import request from '@/utils/request'

//查询收藏列表
export const getLoveList = (data) => {
  return request({
    url: '/dev-api/system/follower/show/1',
    method: 'post',
    data
  })
} 

// 取消收藏
export const delLove = (data) => {
  return request({
    url: '/dev-api/system/follower/deleteById/2',
    method: 'delet',
    data
  })
} 

// export const getPageList = (data) => {
//   return request({
//     url: '/pageList',
//     method: 'post',
//     data
//   })  
// } 

//左侧分类
export const geTypeList = (query) => {
  return request({
    url: '/system/faCategory/listByPage',
    method: 'get',
    params: query
  })
}




