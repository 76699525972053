export default {
  person: {
    name: '登录',
    hobby: '现在免费加入',
    liveBroadcast: '在线专家',
    topExport: '热门专家',
    learnMetaphysics: '奖励俱乐部'
  },
  title: '视频直播',
  getCredits: '购买点数',
  googleText: '谷歌',
  facebookText: 'facebook',
  routeName: {
    history: '历史',
    favorites: '收藏列表',
    Messenger: 'Messenger',
    Category: '类别',
    CategoryObj: {
      child1: '占星',
      child2: 'Tarot',
      child3: '千里眼',
      child4: 'Dream interpretation',
    },
    Topics: '题目',
    TopicsObj: {
      child1: '爱情',
      child2: 'Career',
      child3: '家庭',
      child4: '金钱',
      child5: '宠物',
      child6: '梦想',
    },
    OnlineExpert: '在线专家',
    HotExpert: '热门专家',
    RewardsClub: '奖励俱乐部'

  },
  page: {
    history: '历史',
    favorites: '收藏',
  },
  setting: {
    liveBroadcastManagement: '直播管理',//直播管理
    extractServer: '提取服务器',//提取服务器
    streamKey: '流密钥',//流密钥
    openLiveRoom: '开启直播',//开启直播间
    closeLiveRoom: '关闭直播',//开启直播间
    roomIndex: '房间号'//房间号
  },
  call: {
    turnOffTheCall: '关闭通话'//关闭通话
  },
  messageCall: {
    tips: '向您发起私聊',//有人向您发起私聊
    accept: '接收',//接收
    refuse: '拒绝',//拒绝
    rejected: '已拒绝通话'//已拒绝通话
  },

  YouHave: '你拥有',
  presented: '送出',
  Credits: '点数',
  buyCredits: '买点数',
  BUYCREDITS: '买点数',
  youdonthave: '你没有足够的点数',
  gift: '赠送',
  STARTPRIVATESESSION: '启动私有会话',
  payFootOne: '所有货币的汇率都是按照最新市场价格更新的。',
  payFootTwo: '改变您的支付方式或注册卡，点击',
  payFootTwoType: '支付方式',
  KEFU: '联络客服',
}


