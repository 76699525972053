<template>
  <div class="component-upload-image">
    <el-upload
      v-model:file-list="fileList"
      :before-upload="handleBeforeUpload"
      :class="[
        'upload-demo',
        { 'avatar-mode': mode === 'avatar' },
        { hide: this.fileList.length >= this.limit },
      ]"
      multiple
      :action="uploadImgUrl"
      :list-type="
        mode === 'default' ? 'picture-card' : mode === 'live' ? 'text' : 'picture-card'
      "
      :drag="mode === 'live'"
      :on-success="handleUploadSuccess"
      :limit="limit"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      ref="imageUpload"
      :on-remove="handleDelete"
      :show-file-list="true"
      :headers="headers"
      :on-preview="handlePictureCardPreview"
      :disabled="disabled"
    >
      <el-button v-if="mode === 'default'" type="primary">Click to upload</el-button>
      <template #tip v-if="['default', 'live'].includes(mode)">
        <div class="el-upload__tip">jpg/png files with a size less than 1M.</div>
      </template>

      <template v-if="mode === 'avatar'">
        <el-avatar :size="100" :src="circleUrl" />
      </template>
      <template v-if="mode === 'live'">
        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
        <div class="el-upload__text">
          You don't have any Profile Photos yet.
          <em>upload one Now</em>
        </div>
      </template>
    </el-upload>
    <!-- :width="widthFlag? '100%':'800px' " -->
    <el-dialog
      v-model="dialogVisible"
      :width="widthFlag ? '100%' : '800px'"
      append-to-body
      :show-close="false"
    >
      <img :src="dialogImageUrl" style="display: block; width: 100%; margin: 0 auto" />
    </el-dialog>
  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import { UploadFilled } from "@element-plus/icons-vue";
export default {
  props: {
    value: [String, Object, Array],
    imgUrl: [String, Object, Array],
    // 图片数量限制
    limit: {
      type: Number,
      default: 5,
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ["png", "jpg", "jpeg"],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true,
    },
    tipTxt: {
      type: Boolean,
      default: true,
    },
    token: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      widthFlag: false,
      number: 0,
      uploadList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      hideUpload: false,
      ImageUrl: process.env.VUE_APP_BASE_ImageUrl,
      uploadImgUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      fileList: [],
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  watch: {
    imgUrl: {
      handler(val) {
        if (val) {
          // 首先将值转为数组
          const list = Array.isArray(val) ? val : this.imgUrl.split(",");
          console.log(list, "list");
          // 然后将数组转为对象数组
          this.fileList = list.map((item) => {
            if (typeof item === "string") {
              const urlPrefix = item.includes(this.ImageUrl) ? "" : this.ImageUrl;
              item = {
                name: urlPrefix + item,
                url: urlPrefix + item,
              };
            }
            return item;
          });
        } else {
          this.fileList = [];
          return [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (window.innerWidth < 769) {
      this.widthFlag = true;
    } else {
      this.widthFlag = false;
    }
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
  },
  methods: {
    // 上传前loading加载
    handleBeforeUpload(file) {
      let isImg = false;
      if (this.fileType.length) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        isImg = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
      } else {
        isImg = file.type.indexOf("image") > -1;
      }
      if (!isImg) {
        this.$message.error(
          `File format is not correct, please upload  png / jpg / jpeg image format file! `
        );
        return false;
      }
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(
            `Upload profile picture size cannot exceed ${this.fileSize} MB! `
          );
          // this.$message.error(`上传头像图片大小不能超过 ${this.fileSize} MB!`)
          return false;
        }
      }
      // this.$message.success('正在上传图片，请稍候...')
      // this.$message.success('Uploading pictures, please wait... ')
      this.number++;
    },
    // 文件个数超出
    handleExceed() {
      // this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`)
      this.$message.error(`The number of uploaded files cannot exceed ${this.limit}! `);
    },
    // 上传成功回调
    handleUploadSuccess(res, file) {
      if (res.code === 200) {
        console.log(this.ImageUrl, "this.ImageUrl");
        // this.$emit('update:imgUrl', res.data.url)
        let url = this.ImageUrl + res.data.url;
        console.log(url, "value of url");
        this.uploadList.push({ name: res.data.name, url: url });
        console.log(this.uploadList, "this.uploadList");
        this.uploadedSuccessfully();
      } else {
        this.number--;
        // this.$modal.closeLoading()
        //   this.$message.error(res.msg)
        this.$refs.imageUpload.handleRemove(file);
        this.uploadedSuccessfully();
      }
    },
    // 删除图片
    handleDelete(file, list) {
      console.log("file,list", file, list);
      this.fileList = this.fileList.filter((item) => item.url !== file.url);
      this.updateImgUrl();
      this.$emit("handleDeleteImg", file);
      // const findex = this.fileList.map((f) => f.name).indexOf(file.name)
      // if (findex > -1) {
      //   this.fileList.splice(findex, 1)
      //   // this.$emit('input', this.listToString(this.fileList))
      //   // this.$emit('update:imgUrl', this.fileList)
      // }
    },
    // 上传失败
    handleUploadError() {
      this.$message.error("The image size cannot exceed 1 MB!");
      // this.$modal.closeLoading()
    },
    // 上传结束处理
    uploadedSuccessfully() {
      console.log("进入回调");
      console.log(this.number, "this.number");
      console.log(this.uploadList.length, "this.uploadList");
      if (this.number > 0 && this.uploadList.length === this.number) {
        this.fileList = this.fileList.filter((item) => !item.raw).concat(this.uploadList);
        console.log(this.fileList, "   this.fileList ");
        this.uploadList = [];
        this.number = 0;
        // this.$emit('input', this.listToString(this.fileList))
        // this.$emit('update:imgUrl', this.fileList)
        this.updateImgUrl();

        // this.$modal.closeLoading()
      }
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 对象转成指定字符串分隔
    listToString(list, separator) {
      let strs = "";
      separator = separator || ",";
      for (let i in list) {
        if (list[i].url) {
          strs += list[i].url.replace(this.baseUrl, "") + separator;
        }
      }
      return strs != "" ? strs.substr(0, strs.length - 1) : "";
    },
    updateImgUrl() {
      const withoutIpList = this.fileList.map((item) => ({
        ...item,
        url: item.url.split(this.ImageUrl)[1],
      }));
      let url = this.limit > 1 ? withoutIpList : withoutIpList[0].url;
      this.$emit("update:imgUrl", url);
    },
  },
};
</script>
<style scoped lang="scss">
// .el-upload--picture-card 控制加号部分
::v-deep.hide .el-upload--picture-card {
  display: none;
}

// 去掉动画效果
::v-deep .el-list-enter-active,
::v-deep .el-list-leave-active {
  transition: all 0s;
}

::v-deep .el-list-enter,
.el-list-leave-active {
  opacity: 0;
  transform: translateY(0);
}

.avatar-mode {
  :deep(.el-upload) {
    background-color: transparent;
    border-radius: 50%;
    border: none 0;
  }

  :deep(.el-upload-list--picture-card) {
    .el-upload-list__item {
      border-radius: 50%;
    }

    .el-upload-list__item-thumbnail {
      object-fit: cover;
    }
  }
}

/* 移动端样式 */
@media screen and (max-width: 768px) {
  :deep(.el-dialog) {
    --el-dialog-width: 300px !important;
  }
}
</style>
