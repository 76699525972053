<template>
  <div class="app-main">
    <el-main>
      <router-view v-slot="{ Component, route }">
        <keep-alive>
          <component :is="Component" :key="route.path" />
        </keep-alive>
      </router-view>
    </el-main>
  </div>
</template>
<script setup>
import { useRoute } from 'vue-router'
const route = useRoute()
const getTitle = (route) => {
  let title = ''
  if (!route.meta) {
    // 处理无 meta 的路由
    const pathArr = route.path.split('/')
    title = pathArr[pathArr.length - 1]
  } else {
    title = route.meta.title
  }
  return title
}
</script>
<style lang="scss" scoped>
/* 移动端样式 */
@media screen and (max-width: 768px) {
  .app-main {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    top: 60px;
    .el-main {
      flex-grow: 1;
      padding: 0px;
    }
  }

  .breadcrumb-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    line-height: 50px;
    margin-right: 64px;

    .breadStyle {
      margin-left: 9px;
    }
  }

  .app-main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #d8dce5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  }

  :deep(.el-card__body) {
    padding-top: 0;
  }
}

/* PC端样式 */
@media screen and (min-width: 769px) {
  .app-main {
    min-height: 800px;
    width: 100%;
    height: 100%;
    // min-width: 990px;
    position: relative;
    overflow: hidden;
    // background-color: #10525e;
  }

  .breadcrumb-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    line-height: 50px;
    margin-right: 64px;

    .breadStyle {
      margin-left: 9px;
    }
  }

  .app-main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #d8dce5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  }

  :deep(.el-card__body) {
    padding-top: 0;
  }
}
</style>
