<style lang="scss" scoped>
/* PC端样式 */
@media screen and (min-width: 769px) {
  :deep(.el-slider__runway) {
    height: 3px;
  }

  :deep(.el-slider__button) {
    border: solid 1px #3f4f5f;
    height: 15px;
    width: 15px;
  }

  :deep(.el-slider__bar) {
    background-color: #303133;
    height: 3px;
  }

  :deep(.el-slider__stop) {
    background-color: #d3d3d3;
    height: 3px;
  }

  /* 在屏幕宽度大于等于 769px 时应用下列样式 */

  .bannerContMobile {
    display: none;
  }

  .noseach_data {
    margin-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .noseach_data_txt {
      margin-bottom: 32px;
      font-size: 24px;
      font-weight: bold;
    }

    .noseach_data_btn {
      height: 50px;
      line-height: 50px;
      padding: 0 40px;
      font-size: 24px;
      background: #ffe400;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .searchCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border-radius: 20px;
    border: 1px solid #eadded;
    padding: 20px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.14901960784313725);
    background-color: white;

    .searchCol {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      margin-right: 3rem;

      .label {
        font-size: 16px;
        padding-bottom: 12px;
        font-weight: 600;
        color: #565656;
      }

      .zw-input {
        width: 100%;
        height: 40px;
        border: 1px solid #e2e2e2;
        box-shadow: inset 0 1px 4px #ddd;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 700;
        padding: 8px;
      }
    }

    .listBtnCont {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      display: flex;

      .btnCont {
        flex: 1;
        padding: 20px 30px;
        border-radius: 6px;
        cursor: pointer;
        margin-right: 10px;
        background-color: red;
        color: white;
        border: 1px solid red;
        font-size: 14px;
        font-weight: 800;

        &.clear {
          background-color: #d7d7d7;
          border: 1px solid #d7d7d7;
          color: black;
        }
      }
    }
  }

  .filterCont {
    display: none;
  }

  .slider-cont {
    width: 350px;
    padding-right: 30px;

    .label {
      font-size: 16px;
      padding-bottom: 12px;
      font-weight: 600;
      color: #565656;
    }

    .min-max-cont {
      height: 40px;
      margin-top: 17px;
    }
  }

  .search {
    text-align: right;

    .search-input {
      width: 300px;
      margin-bottom: 10px;
      margin-top: -5px;
      background-color: rgba(240, 239, 239, 0.776);
      color: red !important;
    }

    :deep(.el-input__wrapper) {
      background-color: transparent !important;
    }

    // :deep(.el-input__inner) {
    //   color: #ffffff;
    // }
  }

  .home-page {
    width: 100%;
    padding: 10px 0px;

    .page-title {
      display: flex;
      align-items: center;
      line-height: 20px;
      font-size: 20px;
      margin-bottom: 5px;

      h3 {
        margin-right: 6px;
        color: #ffffff;
      }

      h4 {
        margin-right: 6px;
        color: #d3c2e5;
      }

      p {
        color: #d3c2e5;
      }
    }

    .page-list {
      width: 100%;
      overflow: hidden;
      min-width: 990px;
      position: relative;
      margin-bottom: 20px;

      .page-swiper {
        width: 100%;

        li {
          width: calc(98% - 0px);
          // margin-right: 10px;
          list-style: none;
          font-size: 12px;
          overflow: auto;
          cursor: pointer;
          border-radius: 5px;

          .list-img {
            position: relative;
            height: 190px;

            img {
              // width: 375px;
              object-fit: cover;
              display: block;
              width: 100%;
              height: 100%;
            }

            .img-icon {
              position: absolute;
              top: 10px;
              right: 8px;
              cursor: pointer;
            }

            .img-peple {
              position: absolute;
              color: #ffffff;
              background-color: #0fd6cc;
              top: 10px;
              left: 8px;
              font-size: 12px;
              padding: 3px;
              border-radius: 6px;
            }

            .word-icon {
              position: absolute;
              top: 10px;
              right: 8px;
              cursor: pointer;

              img {
                width: 20px;
                height: 20px;
                display: block;
              }
            }
          }
        }

        .list-category {
          font-size: 12px;
          height: 70px;
          background: #ffff;

          .list-message {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4px;
            margin-right: 4px;

            .message-right {
              width: 50%;
              margin: 5px;
              display: flex;
              align-items: center;

              img {
                width: 30px;
                height: 30px;
                border-radius: 30px;
                margin-right: 10px;
                object-fit: cover;
              }

              .message-name {
                width: 80%;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 12px;
                cursor: pointer;
              }
            }

            .no-icon {
              margin-right: 10px;
              margin-top: 5px;
              width: 30%;

              p {
                cursor: pointer;
                text-align: center;
                padding: 8px 0px;
                background-color: #0477a5;
                border-radius: 6px;
                color: #ffffff;
                font-size: 12px;
              }
            }

            .message-icon {
              display: flex;

              p {
                margin: 4px 0;
                background-color: rgba(162, 219, 236, 0.5);
                border-radius: 18px;
                padding: 4px 6px;
                margin-right: 6px;
              }

              .icon1 {
                font-size: 18px;
              }
            }
          }

          .small-type {
            line-height: 20px;
            height: 20px;
            display: flex;
            flex-wrap: nowrap;
            // overflow: hidden;
            overflow-y: hidden;
            border-radius: 10px;
            cursor: pointer;

            p {
              background-color: rgba(162, 219, 236, 0.5);
              margin-left: 7px;
              display: block;
              padding: 0px 6px;
              border-radius: 8px;
            }
          }
        }
      }

      .prev {
        position: absolute;
        bottom: 75px;
        cursor: pointer;
      }

      .next {
        position: absolute;
        bottom: 75px;
        right: 100px;
        cursor: pointer;
      }
    }

    .list-title {
      display: flex;
      line-height: 20px;
      font-size: 20px;
      margin-bottom: 10px;

      h3 {
        margin-right: 6px;
        color: #ffffff;
      }

      h4 {
        margin-right: 6px;
        color: #d3c2e5;
      }

      p {
        color: #d3c2e5;
      }
    }

    .list-word {
      // width: 100%;
      // display: flex;
      // flex-wrap: wrap;
      grid-template-columns: repeat(auto-fill, minmax(220px, 2fr));
      display: grid;
      grid-column-gap: 20px;
      grid-row-gap: 25px;
    }

    .show-more {
      color: #ffe400 !important;
      display: block;
      padding-top: 8px;
      margin: 0 auto;
      clear: both;
      overflow: hidden;
      text-align: center;
      font-size: 12px;
      line-height: 10px;
      cursor: pointer;
      text-transform: capitalize;
    }

    .show-more:before {
      right: 16px;
      margin-left: -50%;
    }

    .show-more:after {
      left: 16px;
      margin-right: -50%;
    }

    .show-more:before,
    .show-more:after {
      content: "";
      position: relative;
      display: inline-block;
      width: 50%;
      height: 1px;
      vertical-align: middle;
      background-color: #ffffff2a;
    }

    .footer {
      margin-top: 20px;
      color: #ffffff;
      font-size: 12px;

      h5 {
        line-height: 30px;
      }
    }
  }

  .life {
    height: 25px;
    width: 75px;
    overflow: hidden;
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    border: 2px solid white;
    border-radius: 50px;
    text-align: center;
    line-height: 25px;
    color: white;
  }

  .life-online {
    background-color: rgb(115, 201, 64);
  }

  .life-busy {
    background-color: red;
  }

  .life-live {
    background-color: #46a6ff;
  }

  .line-offline {
    background-color: #b6b6b6;
  }

  .p1 {
    cursor: not-allowed;
    background-color: #b6b6b6;
    pointer-events: none;
  }

  .range-value {
    position: absolute;
    top: -2rem;
  }

  .range-value.min {
    left: 0;
  }

  .range-value.max {
    right: 0;
  }

  .track-container {
    width: 100%;
    position: relative;
    cursor: pointer;
    height: 0.5rem;
  }

  .track,
  .track-highlight {
    display: block;
    position: absolute;
    width: 100%;
    height: 0.5rem;
  }

  .track {
    background-color: #ddd;
  }

  .track-highlight {
    background-color: black;
    z-index: 2;
  }

  .track-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    display: block;
    position: absolute;
    z-index: 2;
    width: 1.5rem;
    height: 1.5rem;
    top: calc(-50% - 0.25rem);
    margin-left: -1rem;
    border: none;
    background-color: black;
    -ms-touch-action: pan-x;
    touch-action: pan-x;
    transition: box-shadow 0.3s ease-out, background-color 0.3s ease,
      -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
      background-color 0.3s ease;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
      background-color 0.3s ease, -webkit-transform 0.3s ease-out;
  }

  .showMoreButtonCont {
    text-align-last: center;
    margin-top: 20px;
  }

  .button {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 18px;
    border-radius: 4px;
    background-color: rgb(189, 219, 228);
    border: 1px solid #ffff;
    cursor: pointer;
    margin: 0 5px;
    box-shadow: rgb(90, 181, 209) 0px -3px 0px inset;
  }
}

/* 移动端样式 */
@media screen and (max-width: 768px) {
  /* 在屏幕宽度小于等于 768px 时应用下列样式 */
  .bannerCont {
    display: none;
  }
  .search {
    position: fixed;
    top: 40;
    left: 0;
    width: 100%;
    height: 30px;
    /* 设置固定的高度 */
    background-color: #ffffff;
    /* 设置背景颜色 */
    z-index: 999;
    /* 设置层级，确保弹窗显示在其他内容上面 */
  }

  .list-title {
    display: none;
  }

  .list-word {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  #moblieList {
    overflow: hidden;
    display: grid;
    justify-content: flex-start;
    // overflow-y: auto;
    // height: calc(100vh - 270px);
    // padding-bottom: 50px;
  }

  .home-page {
    overflow-y: auto;
    height: calc(100vh - 280px);
  }

  .show-more {
    color: rgb(90, 181, 209);
    display: flex;
    justify-content: center;
  }

  .footer {
    display: none;
  }

  .mobile_title {
    display: none;
  }

  .noseach_data {
    height: 100vh;
    text-align: center;
    overflow-y: hidden;
  }

  .modalMobile {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 100;
  }

  .searchCont {
    display: none;
  }

  .filterGroupCont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .filterCont {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 10px;
      background: #fff;
      border-radius: 50px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      flex: 1;
      margin-bottom: 10px;

      &.sec {
        margin-bottom: 0px;
      }

      .filterIcon {
        height: 15px;
        width: 15px;
        object-fit: contain;
      }

      .filterText {
        color: black;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
      }
    }
  }

  .showMoreButtonCont {
    text-align-last: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .button {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 18px;
    border-radius: 4px;
    background-color: rgb(189, 219, 228);
    border: 1px solid #ffff;
    cursor: pointer;
  }
}
</style>
<template>
  <div class="bannerContMobile">
    <img
      @click="getCouponFn"
      src="@/assets/free_coupon_banner_mobile.gif"
      style="
        width: 100%;
        height: 60px;
        object-fit: cover;
        cursor: pointer;
        border-radius: 10px;
      "
    />
    <img
      src="@/assets/web_introduction_mobile.png"
      style="
        width: 100%;
        height: 100px;
        object-fit: cover;
        line-height: 0;
        border-radius: 10px;
      "
    />
  </div>
  <div class="modalMobile" v-if="modelShow">
    <Modal :closeModal="closeModal" :applyFilter="applyFilter" />
  </div>

  <div class="bannerCont">
    <img
      src="@/assets/web_introduction_web.png"
      style="width: 100%; height: 180px; object-fit: cover; border-radius: 10px"
    />
  </div>

  <div class="searchCont">
    <div class="searchCol">
      <div class="label">Search by topics, expertise, advisor name</div>
      <input
        class="zw-input"
        v-model="message"
        placeholder="Find advisors"
        @keyup.enter="ApplySearch"
      />
    </div>
    <div class="slider-cont">
      <div class="label">Price per minute</div>
      <div>
        <el-slider v-model="minVal" range show-stops :max="maxPerMinVal" />
      </div>
      <div class="minmax-inputs">
        <p step="0.5">${{ minVal[0] }}</p>
        <p step="0.5">${{ minVal[1] }}</p>
      </div>
    </div>
    <div class="listBtnCont">
      <button class="btnCont" @click="ApplySearch">Apply</button>
      <button class="btnCont clear" @click="applyFilter">Clear</button>
    </div>
  </div>

  <div class="filterGroupCont">
    <div class="filterCont" @click.stop="openModal">
      <img src="@/assets/sort.png" class="filterIcon" />
      <div style="margin-right: 10px" />
      <div class="filterText">Filter</div>
    </div>

    <div class="filterCont sec" v-if="!!message">
      <div class="filterText">{{ message }}</div>
      <div style="margin-right: 10px" />
      <img src="@/assets/close_grey.png" class="filterIcon" @click="applyFilter()" />
    </div>
  </div>

  <div class="home-page" v-if="!data.isHideSwiper">
    <!-- <div class="list-title">
        <h3>RECOMMENDED</h3>
        <h4>EXPERTS</h4>
        <p>
          Top Favourites by our Members! You can't go wrong with our recommended
          Experts!
        </p>
      </div> -->
    <ul
      class="list-word"
      v-if="data.listArr.length == 0"
      style="width: 100%; display: flex; justify-content: space-between"
    >
      <div v-for="(item, index) in 4" :key="index">
        <el-skeleton style="width: 401px" animated>
          <template #template>
            <el-skeleton-item variant="image" style="width: 100%; height: 196px" />
            <div class="word-category">
              <div class="word-message">
                <div class="message-right">
                  <el-skeleton-item variant="circle" style="width: 30px; height: 30px" />
                  <el-skeleton-item class="message-name" variant="text" />
                </div>
                <div class="no-icon" v-if="page1StoreDa.token == ''">
                  <el-skeleton-item class="message-name" variant="button" />
                </div>
              </div>
              <div style="display: flex; justify-content: space-around">
                <el-skeleton-item
                  class="message-name"
                  variant="p"
                  style="width: 60px; height: 10px"
                />
                <el-skeleton-item
                  class="message-name"
                  variant="p"
                  style="width: 60px; height: 10px"
                />
                <el-skeleton-item
                  class="message-name"
                  variant="p"
                  style="width: 60px; height: 10px"
                />
              </div>
            </div>
          </template>
        </el-skeleton>
      </div>
    </ul>

    <ul
      class="list-word"
      id="moblieList"
      v-if="data.listArr.length !== 0"
      @scroll="scrolling"
    >
      <cardBody
        :cardObject="item"
        v-for="(item, index) in data.listArr"
        :key="index"
      ></cardBody>
    </ul>
    <!-- <Footer></Footer> -->
  </div>

  <div class="home-page" v-else>
    <ul class="list-word" id="moblieList" v-if="data.searchList.length > 0">
      <cardBody
        :cardObject="item"
        v-for="(item, index) in data.searchList"
        :key="index"
      ></cardBody>
    </ul>
    <div v-else class="noseach_data">
      <img src="@/assets/nohappy.png" alt="" />
      <div class="noseach_data_txt">The expert you searched for could not be found.</div>
      <div class="noseach_data_btn" @click="applyFilter()">
        Click here to see more experts.
      </div>
    </div>
    <div> 
      <div v-if="showmorecontent == 'Show More'" class="showMoreButtonCont bannerContMobile">
    <b-button class="button" @click="showMore">{{ showmorecontent }}</b-button>
  </div>
    </div>
  </div>
  <div v-if="showmorecontent == 'Show More'" class="showMoreButtonCont bannerCont">
    <b-button class="button" @click="showMore">{{ showmorecontent }}</b-button>
  </div>
  <div
    v-else
    style="color: rgb(182, 182, 182); text-align-last: center; margin-top: 20px"
  >
    <a @click="showMore"></a>
  </div>
</template>
<script setup>
import { phoneStore } from "../../store/phone1";
import cardBody from "@/components/cardBody";
import {
  reactive,
  onMounted,
  ref,
  computed,
  nextTick,
  inject,
  watch,
  onUnmounted,
} from "vue";
import { debounce } from "lodash";
import { Search } from "@element-plus/icons-vue";
import { useRouter, useRoute } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue"; // swiper 所需组件
import { Navigation } from "swiper"; // 分页器
import { page1Store } from "@/store/page1";
import "swiper/swiper-bundle.css";
import request from "@/utils/request.js";
import { ElMessage, ElNotification } from "element-plus";
import { searchList } from "@/api/center.js";
import { getToken, getType } from "@/utils/auth";
import { useStore } from "vuex";
import bus from "vue3-eventbus";
import vueConfig from "../../../vue.config";
import Footer from "../../layout/components/Footer.vue";
import CustomMinMaxSlider from "@/components/CustomMinMaxSlider";
import Modal from "@/components/Modal";
import iNotify from "@/utils/inotify.js";

// import 'swiper/css/navigation'
// const testArr = ref([true,false,"333"])
const maxPerMinVal = ref(0);
const minVal = ref([0, 0]);
const innerWidthSearch = ref(false);
const store1 = phoneStore();
const store = useStore();
const loading = ref(false);
const router = useRouter();
const route = useRoute();
const page1StoreDa = page1Store();
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const sliderMin = ref(0);
const sliderMax = ref(17);
const modelShow = ref(false);
const message = ref("");
const socket = inject("socket");
let ws;
const pageNum = ref(1);
const pageSize = ref(16);
const state = reactive({
  target: "",
  random: Math.random(),
});
onMounted(() => {
  history.pushState(null, null, document.URL);
  window.addEventListener("scroll", handleScroll);

  window.addEventListener(
    "popstate",
    function (e) {
      history.pushState(null, null, document.URL);
    },
    false
  );
  store1.serachShow = false;
  maxPerMin();
  if (route.query) {
    let data1 = {
      minPerMinute: route.query.min,
      maxPerMinute: route.query.max,
      searchKeys: route.query.q,
    };

    // return
    getSearch(data1);
    message.value = route.query.q;
    return;
  }
  if (window.innerWidth < "768") {
    innerWidthSearch.value = true;
  }
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

const handleScroll = (event) => {
  let bottomOfWindow =
    event.target.documentElement.scrollTop + event.target.documentElement.clientHeight >=
    event.target.documentElement.scrollHeight;

  if (bottomOfWindow) {
    showMore();
  }
};

const ApplySearch = async () => {
  pageNum.value = 1;
  console.log(minVal.value, "success");
  let data1 = {
    minPerMinute: minVal.value[0],
    maxPerMinute: minVal.value[1],
    searchKeys: message.value,
    pageNum: 1,
    pageSize: 16,
  };
  let res = await searchList(data1);
  console.log(res.rows, "resres");

  data.searchList = res.rows;
  if (data.searchList.length < res.total) {
    showmorecontent.value = "Show More";
  } else {
    showmorecontent.value = "no more";
  }

  // router.push({
  //   path: "/onlineExpert",
  //   query: { q: data1.searchKeys, min: data1.minPerMinute, max: data1.maxPerMinute },
  // });
};

//获取顾问每分钟最大金额收费
const maxPerMin = async () => {
  const { data } = await request.get("/system/faConsultant/maxPerMinute");
  maxPerMinVal.value = data;
  minVal.value = [0, data];
};

const scrolling = (e) => {
  console.log("滚动");
  const clientHeight = e.target.clientHeight;
  const scrollHeight = e.target.scrollHeight;
  const scrollTop = e.target.scrollTop;
  if (scrollTop + clientHeight >= scrollHeight) {
    console.log("到底了!");
    bottom.value = true;
  } else {
    bottom.value = false;
  }
};

watch(
  () => store1.onLineList.map((item) => item.collect),
  (newVal, oldVal) => {
    // console.log(newVal, oldVal, 'new odl')
    data.pageArr = store1.onLineList;
  },
  { deep: true }
);

watch(
  () => store1.allList.map((item) => item.collect),
  (newVal, oldVal) => {
    let arr = store1.allList;
    data.listArr = Array.from(new Set(arr.map(JSON.stringify))).map(JSON.parse);
  },
  { deep: true }
);

const token = computed(() => {
  return window.localStorage.getItem("token");
});
const showmorecontent = ref("Show More");
const data = reactive({
  pageArr: [],
  listArr: [],
  currentIndex: 0,
  time: null,
  searchValue: "", //搜索框绑定的值
  typeList: [],
  isHideSwiper: false,
  searchList: [],
});

//优惠券
const getCoupon = async () => {
  if (getType() == "1" || !getToken()) {
    bus.emit("LoginShow", true);
    return;
  }

  console.log("优惠券");
  let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
  console.log(`${userInfo.id}`);
  let data = {
    userId: userInfo.id, //用户id
    discountId: 2, //优惠券id
  };
  const res = await request.post("/system/faUserDiscount/getCoupon", data);
  console.log(res, "data");
  if (res.code == "200" && res.data == "1") {
    // da.isCouponShow = true
    // couponList.value = data
    ElMessage({
      message: res.msg,
      type: "success",
    });
    return;
    // da.isCouponShow = true
  }

  if (res.code == "200" && res.data == "0") {
    // da.isCouponShow = true
    // couponList.value = data
    ElMessage({
      message: "This coupon has been claimed.",
      type: "message",
    });
    return;
    // da.isCouponShow = true
  }
};

const getCouponFn = debounce(getCoupon, 500); // 500表示延迟时间，您可以根据需要进行调整

//搜索事件
const getSearch = (q) => {
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
  };
  searchList({ ...q, ...params }).then((res) => {
    if (res.code == 200) {
      data.isHideSwiper = true;
      data.searchList = res.rows;

      if (data.searchList.length < res.total) {
        showmorecontent.value = "Show More";
      } else {
        showmorecontent.value = "no more";
      }
    }
  });
  store1.serachShow = false;
};

const openModal = () => {
  modelShow.value = true;
};

const closeModal = () => {
  modelShow.value = false;
};

const applyFilter = (q) => {
  // maxPerMin();
  // getLiveList();
  modelShow.value = false;
  if (Object.prototype.toString.call(q) == "[object Object]") {
    router.push({
      path: "/onlineExpert",
      query: { q: q.searchKeys, min: q.minPerMinute, max: q.maxPerMinute },
    });
    return;
  } else if (Object.prototype.toString.call(q) == "[object String]") {
    router.push({
      path: "/onlineExpert",
      query: { q: q.searchKeys },
    });
  } else {
    maxPerMin();
    searchList({
      pageNum: 1,
      pageSize: 16,
    }).then((res) => {
      data.searchList = res.rows;
      if (data.searchList.length < res.total) {
        showmorecontent.value = "Show More";
      } else {
        showmorecontent.value = "no more";
      }
      pageNum.value = 1;
    });
    message.value = "";
    router.push({ path: "/onlineExpert" });
    return;
  }
};

// 获取热点房间
async function getFaConsultant() {
  const { code, rows } = await request.get(
    "/system/faConsultant/list?statuArr=1,2&isLive=live"
  );
  // console.log(rows)
  if (code == 200) {
    store1.onLineList = rows;
    data.pageArr = store1.onLineList;
  }
}
//获取直播列表
async function getLiveList() {
  loading.value = true;
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
  };
  /* /system/liveRoom/list */
  const { code, rows, total } = await request.get("/system/faConsultant/list", {
    params: params,
  });
  if (code == 200 && pageNum.value <= Math.ceil(total / pageSize.value)) {
    console.log("添加");
    loading.value = false;
    store1.allList = data.listArr.concat(rows);
    data.listArr = store1.allList;
    console.log(data.listArr, "data.list");
  }
  if (pageNum.value >= Math.ceil(total / pageSize.value)) {
    showmorecontent.value = "no more";
  }
}
// 展示更多
const showMore = () => {
  pageNum.value += 1;
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    minPerMinute: route.query.min,
    maxPerMinute: route.query.max,
    searchKeys: route.query.q,
  };
  searchList(params).then((res) => {
    if (res.code == 200 && pageNum.value <= Math.ceil(res.total / pageSize.value)) {
      data.searchList = data.searchList.concat(res.rows);
      console.log(data.searchList, "data.searchList");
    }
    if (pageNum.value >= Math.ceil(res.total / pageSize.value)) {
      showmorecontent.value = "no more";
    }
  });
};

watch(
  () => route.query,
  (newVal, oldVal) => {
    message.value = newVal.q;
    if (newVal) {
      console.log(newVal, "newVal");

      let data1 = {
        minPerMinute: newVal.min,
        maxPerMinute: newVal.max,
        searchKeys: newVal.q,
      };
      getSearch(data1);
    }
  },
  { deep: true }
);
</script>
