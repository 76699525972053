

import { defineStore } from "pinia";
import { reactive, toRefs, ref,watch } from "vue";
import piniaPersistConfig from "./persist.js";
export const page3Store = defineStore(
  'page3Store',
  () => {
    const state = reactive({
      username:'',
      token:'',
      regStutas:'',
      isLoginShow: false,
      isRegisterShow: false,
    
    })

    return {
      ...toRefs(state),
     
    }
  },

  {
    //持久化数据,配置数组可以选择需要持久化的数据，如需要全部持久化，使用 persist：true
    persist: [
      piniaPersistConfig(
        'page3Store',
        [
          'token',
          'username',
          'regStutas',
          'isLoginShow',
          'isRegisterShow',
     
        ], /*'localStorage',不传默认sessionstorage */
      ),
    ],
  }

)
