import request from '@/utils/request'

//获取信息
export const getConInformation = (query) => {
  return request({
    url: '/system/FaUser/getUserInfo',
    method: 'get',
    params: query
  })
}

//获取顾问信息
export const getConmation = (query) => {
  return request({
    url: '/system/faConsultant/getLoginConsuInfo',
    method: 'get',
    params: query
  })
}

//顾问收益列表
export const getMyLiveIncome = (query) => {
  return request({
    url: '/system/liveRecord',
    method: 'get',
    params: query
  })
}


//获取顾问收费信息
export const getConPayInformation = (query) => {
  return request({
    url: '/system/faConsultant/getConsultantInfo',
    method: 'get',
    params: query
  })
}

//修改顾问收费信息
export const reviseConPayInformation = (data) => {
  return request({
    url: '/system/faConsultant/updateConsultantInfo',
    method: 'post',
    data
  })
}

//获取顾问提现列表
export const getWithList = (query) => {
  return request({
    url: '/system/FaWithdrawal/list',
    method: 'get',
    params: query
  })
}

//首页搜索列表
export const searchList = (query) => {
  return request({
    url: '/system/faConsultant/getFaConsultant',
    method: 'get',
    params: query
  })
}

//顾问中心上传图片

export const postImage = (data) => {
  return request({
    url: '/system/faConsultantImage/updateMyGallery',
    method: 'post',
    data
  })
}

//顾问中心价格设置

export const getPrice = (query) => {
  return request({
    url: '/system/faConsultant/getConsultantInfo',
    method: 'get',
    params: query
  })
}

//修改顾问中心价格
export const updataPrice = (data) => {
  return request({
    url: '/system/faConsultant/updateConsultantInfo',
    method: 'post',
    data
  })
}


//顾问中心修改顾问信息
export const reviseConsulantInformation = (data) => {
  return request({
    url: '/system/user/update',
    method: 'post',
    data
  })
}

//顾问中心修改多张图片

export const updateImageList = (data) => {
  return request({
    url: '/system/faConsultantImage/add',
    method: 'post',
    data
  })
}

//获取顾问中心多张图片
export const getMoreImage = (query) => {
  return request({
    url: '/system/faConsultantImage/list?pageSize=1000',
    method: 'get',
    params: query
  })
}

//删除多张顾问中心多张照片
export function delMoreImge(postId) {
  return request({
    url: '/system/faConsultantImage/deleteById/' + postId,
    method: 'delete'
  })
}

//顾问中心获取评论列表

export const getMessageList = (query) => {
  return request({
    url: '/system/faConsultant/getConsultantAppraise',
    method: 'get',
    params: query
  })
}



//删除多张顾问中心评论
export function delMessage(postId) {
  return request({
    url: '/system/faConsultant/deleteAppraise?id=' + postId,
    method: 'delete'
  })
}

//顾问中心查询分类

export const getTypeList = (query) => {
  return request({
    url: '/system/faCategory/listByPage',
    method: 'get',
    params: query
  })
}


//添加顾问中心分类
export const addTypeList = (data) => {
  return request({
    url: '/system/faConsultant/addCategory',
    method: 'post',
    data
  })
}

//顾问修改密码

export const updateConPass = (data) => {
  return request({
    url: '/system/faConsultant/updatePwd',
    method: 'post',
    data
  })
}
//退出后的请求 logOut

export const logOut = (data) => {
  return request({
    url: '/auth/faConsultant/logout',
    method: 'post',
    data
  })
}


export const getEchartsData = (query) => {
  return request({
    url: '/system/faConsultant/incomeStatistics',
    method: 'get',
    params: query
  })
}

//用户充值记录消费记录
export const recordsAPI = (query) => {
  return request({
    url: '/system/FaUserCoinChangeRecord/typeList',
    method: 'get',
    params: query
  })
}









