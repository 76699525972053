import layout from '@/layout'
import { createRouter, createWebHashHistory } from 'vue-router'
import onlineExpert from '@/views/onlineExpert/index'
import pinia from '../store/index'
import { page3Store } from '@/store/page3'
import request from '@/utils/request.js'
import { getToken, getType } from '@/utils/auth'
import { ElMessage, ElMessageBox } from 'element-plus'
import { phoneStore } from "@/store/phone1";
const page1StoreDa = page3Store(pinia)

// const store = useStore()
import bus from 'vue3-eventbus'
// 私有路由表
// const privateRoutes = []
const store1 = phoneStore()
const publicRoutes = [
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  },
  {
    path: '/calendarComponent',
    component: () => import('@/views/calendarComponent/index')
  },
  //mobileScheduler 手机端顾问后台设置预约
  {
    path: '/mobileScheduler',
    component: () => import('@/views/mobileScheduler/index')
  },

  //手机端图表
  {
    path: '/mobileEcharts',
    component: () => import('@/views/mobileEcharts/index')
  },
  {
    path: '/msgDetail/:id',
    component: () => import('@/views/msgDetail/index')
  },
  {
    path: '/mobleMsg',
    component: () => import('@/views/mobleMsg/index')
  },
  {
    path: '/forgotPass',
    component: () => import('@/views/forgotPass/index.vue')
  },
  {
    path: '/404',
    component: () => import('@/views/404')
  },
  {
    path: '/login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/detailPage/:id',
    component: () => import('@/views/detailPage/index')
  },
  {
    path: '/pageQuestion',
    component: () => import('@/views/pageQuestion/index')
  },
  {
    path: '/pageTerm',
    component: () => import('@/views/pageTerm/index')
  },
  {
    path: '/privacyPolicy',
    component: () => import('@/views/pageTerm/privacyPolicy.vue')
  },
  {
    path: '/obsHelp',
    component: () => import('@/views/obsDownload/index')
  },
  //用户个人中心 seetting
  {
    path: '/pageSetting',
    component: () => import('@/views/pageSetting/index'),
    redirect: '/pageSetting/account',
    children: [
      {
        path: 'account',
        component: () => import('@/views/pageSetting/routeView/account.vue')
      },
      {
        path: 'recharge',
        component: () => import('@/views/pageSetting/routeView/recharge.vue')
      },
      //Consumption
      {
        path: 'consumption',
        component: () => import('@/views/pageSetting/routeView/consumption.vue')
      },

      // {
      //   path: 'liveBroadcast',
      //   component: () =>
      //     import('@/views/pageSetting/routeView/liveBroadcast.vue')
      // }
    ]
  },
  {
    path: '/pageReg',
    component: () => import('@/views/pageReg/index')
  },
  {
    path: '/profileInfo',
    component: () => import('@/views/profileInfo/index'),
    redirect: '/profileInfo/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/profileInfo/routeView/home.vue')
      },
      //reservation
      {
        path: 'reservation',
        component: () => import('@/views/profileInfo/routeView/reservation.vue')
      },
      {
        path: 'liveIncome',
        component: () => import('@/views/profileInfo/routeView/liveIncome.vue')
      },
      {
        path: 'Dashboard',
        component: () => import('@/views/profileInfo/routeView/Dashboard.vue')
      },
      {
        path: 'Boardcast',
        component: () => import('@/views/profileInfo/routeView/Boardcast.vue')
      },
      {
        path: 'PrivateChatIncome',
        component: () =>
          import('@/views/profileInfo/routeView/PrivateChatIncome.vue')
      },
      {
        path: 'WithdrawalList',
        component: () =>
          import('@/views/profileInfo/routeView/WithdrawalList.vue')
      },
      {
        path: 'ProfilePhoto',
        component: () =>
          import('@/views/profileInfo/routeView/ProfilePhoto.vue')
      },
      {
        path: 'PersonalData',
        component: () =>
          import('@/views/profileInfo/routeView/PersonalData.vue')
      },

      {
        path: 'Price',
        component: () => import('@/views/profileInfo/routeView/Price.vue')
      },
      {
        path: 'MailboxList',
        component: () => import('@/views/profileInfo/routeView/MailboxList.vue')
      },

      {
        path: 'CommentManagementList',
        component: () =>
          import('@/views/profileInfo/routeView/CommentManagementList.vue')
      },
      {
        path: 'message',
        component: () => import('@/views/profileInfo/routeView/message.vue')
      }
    ]
  },
  {
    path: '/history',
    component: () => import('@/views/history/index'),
    meta: {
      title: 'history',
      icon: 'lishijilu'
    }
  },
  {
    path: '/favorite',
    component: () => import('@/views/favorite/index'),
    meta: {
      title: 'favorites',
      icon: 'aixin'
    }
  },
  // 支付后的操作页面
  {
    path: '/returnHref',
    component: () => import('@/views/returnHref/returnHref.vue')
  },
  // 获取登录的PayPal用户信息的页面
  {
    path: '/paypalLoginReturn',
    component: () => import('@/views/returnHref/paypalLoginReturn.vue')
  },
  {
    path: '/callPlayer/receive',
    name: '接收方',
    component: () => import('@/views/callPlayer/receive')
  },
  {
    path: '/',
    component: layout,
    redirect: '/onlineExpert',
    children: [
      {
        path: '/messenger',
        component: () => import('@/views/messenger/index'),
        meta: {
          title: 'Messenger',
          icon: 'xiaoxi',
          type: 'dialog'
        }
      },
      {
        path: '/onlineExpert',
        component: onlineExpert,
        meta: {
          title: 'OnlineExpert',
          icon: '',
          mode: 'horizontal'
        },

      },
      {
        path: '/hotExpert',
        component: () => import('@/views/hotExpert/index'),
        meta: {
          title: 'HotExpert',
          icon: '',
          mode: 'horizontal'
        }
      },
      // {
      //   path: '/rewardsClub',
      //   component: () => import('@/views/rewardsClub/index'),
      //   meta: {
      //     title: 'RewardsClub',
      //     icon: '',
      //     mode: 'horizontal'
      //   }
      // },
      {
        path: '/categoryPage',
        component: () => import('@/views/category/SchedulingM/index'),
        meta: {
          title: 'Category',
          icon: 'article',
          hide: true
        }
      }
    ]
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes: [...publicRoutes],
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },

});

// 获取顾问信息
async function getLoginConsuInfo() {
  const { code, data } = await request.get(
    '/system/faConsultant/getLoginConsuInfo'
  )
  if (code == 200) {
    // 判断顾问的当前注册状态 0=注册邮箱未认证,1=注册邮箱已认证提交资料审核,2=资料审核通过,3=资料审核驳回
    console.log(data.auditStatus, 'consulant')
    return data
  }
}

router.beforeEach(async (to, from, next) => {
  let pathStr = to.fullPath.substring(0, 12)
  let videoStr = from.fullPath.substring(0, 19)
  if (videoStr == "/callPlayer/receive" && !store1.leaveVideoFlag) {
    ElMessage({
      message: 'Please hang up before leaving this page!',
      type: 'warning'
    })
    next(false);
  } else {
    store1.leaveVideoFlag = false;

    if ('/profileInfo' == pathStr) {
      const infoData = await getLoginConsuInfo()
      if (!infoData) {
        ElMessage({
          message: 'Please log in to your advisor account first!',
          type: 'warning'
        })
        return
      }
      console.log(infoData, 'infoData')
      if (infoData.auditStatus !== '2') {
        ElMessage({
          message: 'Please complete your registration process!',
          type: 'warning'
        })
        router.push('/pageReg')
        return
      } else {
        next()
      }
    }


    let userInfo = window.localStorage.getItem('userInfo')

    const rArr = ['/history', '/favorite', '/messenger']
    if (rArr.includes(to.fullPath) && getType() == '1') {

      bus.emit('LoginShow', true)
      return
    }
    if (rArr.includes(to.fullPath) && !getToken()) {
      // callOpen()

      bus.emit('LoginShow', true)
      page1StoreDa.isLoginShow = true
    } else if (to.meta?.type === 'dialog') {

      bus.emit('chatting', true)
    } else {
      next()
    }

  }
  // else {
  //   next()
  //   store1.leaveVideoFlag = false;
  // }

  // 如果路径等于/returnHref,代表是支付后的操作页面,那么重新请求用户信息，这时候积分在服务器上已经加上去了
  // if (to.path == '/returnHref') {
  // }

})

export default router
