import { h } from 'vue'
import i18n from '@/i18n/index'
import "./style.css"
import { sendWsMsg, openLive } from '@/api/messageCall'
import { ElNotification, ElMessage } from 'element-plus'
import bus from "vue3-eventbus";
export function callOpen(item, router) {
    ElNotification({
        title: item.userName + i18n.global.t('messageCall.tips'),
        message: hBoxCreated(item, router),
        duration: 45000
    })
}
function hBoxCreated(item, router) {
    return h("div", { class: 'messageCall' }, [
        // h("p", { class: 'name' }, `${item.userName}`),
        h("div", { class: 'buttonClass' }, [
            h("button", {
                onClick: () => {
                    ElNotification.closeAll()
                    appect(item, router)
                }, class: "acceptButton"
            }, i18n.global.t('messageCall.accept')),
            h("button", {
                onClick: () => {
                    ElNotification.closeAll()
                    refuse(item)
                    ElMessage({ type: 'success', message: i18n.global.t('messageCall.rejected') })
                }, class: "refuseButton"
            }, i18n.global.t('messageCall.refuse'))
        ])

    ])
}
async function appect(item, router) {
    let type = {
        liveType: 2
    }
    let res = await openLive(type)
    console.log(res.data)
    bus.emit("appectAuto", '1');
    if (res.code == 200) {
        let msg = {
            type: 'appect',
            roomName: res.data.roomName,
            roomId: res.data.id,
            consultantId: res.data.consultantId
        }
        await sendWsMsg(item.userId, 1, msg)
        router.push({ path: '/callPlayer/receive', query: { type: 1, roomName: res.data.roomName, roomId: res.data.id, consultantId: res.data.consultantId } })
    } else {

    }

}
async function refuse(item) {
    bus.emit("appectAuto", '1');
    let msg = {
        type: 'refuse',
    }
    await sendWsMsg(item.userId, 1, msg)
}




