import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import _ from 'lodash'
const pinia = createPinia()
// 因为状态管理使用的是setup的方式构建所以我们重写一个$reset并挂载到pinia中
pinia.use(({ store }) => {
    const initialState = _.cloneDeep(store.$state)
    store.$reset = () => {
        store.$patch(initialState)
    }
})
// 将插件提供给pinia实例
pinia.use(piniaPluginPersistedstate)


export default pinia