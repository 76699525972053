import request from '@/utils/request'

//会员登录
export const login = (data) => {
  return request({
    url: '/auth/faUser/login',
    method: 'post',
    data
  })
}

//会员注册
export const register = (data) => {
  return request({
    url: '/auth/faUser/register',
    method: 'post',
    data
  })
}

export const faceBookLogin = (data) => {
  return request({
    url: '/dev-api/auth/faUser/facebookLogin',
    method: 'post',
    data
  })
}

//会员修改密码
export const updatePassWord = (data) => {
  return request({
    url: '/system/FaUser/updatePwd',
    method: 'post',
    data
  })
}

//会员修改头像
export const updateAvatar = (data) => {
  return request({
    url: '/system/FaUser/updateUser',
    method: 'post',
    data
  })
}


//顾问登录

export const conLogin = (data) => {
  return request({
    url: '/auth/faConsultant/login',
    method: 'post',
    data
  })
}


//顾问注册邮箱
export const conRegister = (data) => {
  return request({
    url: '/auth/faConsultant/register',
    method: 'post',
    data
  })
}

//顾问邮箱验证
export const verifyEmail = (data) => {
  return request({
    url: '/dev-api/auth/faConsultant/activation',
    method: 'post',
    data
  })
}

//three
export const getThree = (params) => {
  return request({
    url: '/auth/faConsultant/activation',
    method: 'get',
    params
  })
}

//顾问注册保存信息
export const postInformation = (data) => {
  return request({
    url: '/auth/faConsultant/updateConsultant',
    method: 'post',
    data
  })
}


//点数购买
export const payCredits = (data) => {
  return request({
    url: '/dev-api/system/paypal/pay',
    method: 'post',
    data
  })
}


//邮箱确认后
export const querEmail = (query) => {
  return request({
    url: '/system/faConsultant/getInfoByEmail',
    method: 'get',
    params: query
  })
}

//4 发送手机号接口
export const sendPhone = (params) => {
  return request({
    url: '/auth/faConsultant/sendSMS',
    method: 'get',
    params
  })
}

//4 校验验证码
export const verifyCode = (data) => {
  return request({
    url: '/auth/faConsultant/checkSMSCode',
    method: 'post',
    data
  })
}

//找回密码
export const forgotPassAPI = (params) => {
  return request({
   // url: '/system/faConsultant/getInfoByEmail',
    url: '/system/faConsultant/forgotPassword',
    method: 'get',
    params,
  })
}

//验证顾问code
export const forgotCodeAPI = (params) => {
  return request({
    // url: '/system/faConsultant/getInfoByEmail',
    url: '/system/faConsultant/forgotPasswordVerification',
    method: 'get',
    params,
  })
}

export const forgotPassapi = (params) => {
  return request({
    // url: '/system/faConsultant/getInfoByEmail',
    url: '/system/faConsultant/forgotPasswordUser',
    method: 'get',
    params,
  })
}

//验证顾问code
export const forgotCodeapi = (params) => {
  return request({
    // url: '/system/faConsultant/getInfoByEmail',
    url: '/system/faConsultant/forgotPasswordVerificationUser',
    method: 'get',
    params,
  })
}












