export default {
  person: {
    name: 'Login',
    hobby: 'Join now for FREE',
    liveBroadcast: 'Live Broadcast',
    topExport: 'Top Export',
    learnMetaphysics: 'Learn Metaphysics'

  },
  title: 'ZESTWISDOM',
  getCredits: 'Get Credits',
  googleText: 'Google',
  facebookText: 'Facebook',
  routeName: {
    history: 'History',
    favorites: 'Favorites',
    Messenger: 'Messenger',
    Category: 'Expertise',
    CategoryObj: {
      child1: 'Astrology',
      child2: 'Tarot',
      child3: 'FengShui',
      child4: 'YiChing',
    },
    Topics: 'Topics',
    TopicsObj: {
      child1: 'Love',
      child2: 'Career',
      child3: 'Family',
      child4: 'Money',
      child5: 'Pets',
      child6: 'Dreams',
    },
    OnlineExpert: 'Online Experts',
    HotExpert: 'Top Experts',
    RewardsClub: 'RewardsClub'
  },
  page: {
    history: 'History',
    favorites: 'Favorites',
  },
  setting: {
    liveBroadcastManagement: 'liveBroadcastManagement',//直播管理
    extractServer: 'extractServer',//提取服务器
    streamKey: 'streamKey',//流密钥
    openLiveRoom: 'openLiveRoom',//开启直播间
    closeLiveRoom: 'closeLiveRoom',//开启直播间
    roomIndex: 'roomIndex'//房间号
  },
  messageCall: {
    tips: ' has initiated a private chat with you',//有人向您发起私聊
    accept: 'Accept',//接收
    refuse: 'Refuse',//拒绝
    rejected: 'The call has been rejected'//已拒绝通话
  },
  YouHave: 'You Have',
  presented: 'gave away',//赠送了
  Credits: 'Credits',
  buyCredits: 'Buy Credits',
  BUYCREDITS: 'BUY CREDITS',
  youdonthave: "You don't have enough Credits",
  gift: 'Gift',
  STARTPRIVATESESSION: 'START PRIVATE SES SION',
  payFootOne: 'All monetary figures are in US dollars.',
  payFootTwo: 'To change your payment method or registration card, click ',
  payFootTwoType: 'Payment method',
  needHelp: 'Need some help?',
  KEFU: 'Contact customer service'
}
