import ancRequest from '@/utils/request'
//ws全连接
export const sendWsMsg = (id, type, data) => {
    return ancRequest({
        url: `/system/liveRoom/call?targetId=${id}&userType=${type}`,
        method: 'post',
        data: data,
        contentType: "application/json;charset=utf-8",
    })
}
//创建直播间
export const openLive = (data) => {
    return ancRequest({
        url: '/system/liveRoom/open',
        method: 'post',
        data: data,
        contentType: "application/json;charset=utf-8",
    })
}
//进入直播间
export const joinRoom = (data) => {
    return ancRequest({
        url: '/system/liveRoom/join',
        method: 'post',
        data: data,
        contentType: "application/json;charset=utf-8",
    })
}
//获取直播间信息
export const getMsg = (params) => {
    return ancRequest({
        url: '/wom-chat-room/chatRoom/getById',
        method: 'get',
        params: params
        //1用户，2顾问
    })
}



export const postMsg = (data) => {
    return ancRequest({
        url: '/wom-chat-room/chatRoom/addChatInfo',
        method: 'post',
        data: data
    })
}