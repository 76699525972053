<template>
  <!-- <div> -->
    <sidebar-menu v-if="da.typeListOrigin.length > 0" :typeList="da.typeListOrigin" @onMenuItemClicked="closeMenu"></sidebar-menu>
  
  <!-- </div> -->
</template>

<script setup name="SideBarIndex">
import SidebarMenu from './SidebarMenu.vue'
import { computed, getCurrentInstance, onMounted, reactive } from 'vue'
import { geTypeList } from '@/api/test.js'

const { $t } = getCurrentInstance().proxy
const da = reactive({
  typeListOrigin: []
})
onMounted(() => {
  getTpye()
})
/* const typeList = computed(() => {
  let arr = da.typeListOrigin.map((item) => item.categoryList).flat()
  // arr.map(item=>{
  //   if(item.categoryList.length){
  //     item.categoryList.map(item2=>{
  //       return {

  //       }
  //     })
  //   }
  //   return item
  // })
  return arr
}) */
const typeList = computed(() => {
  let arr = da.typeListOrigin.reduce((acc, item) => {
    return acc.concat(item.categoryList);
  }, []);
  return arr;
});

//左边列表
const getTpye = () => {
  geTypeList().then((res) => {
    // console.log(res.rows, '左边列表')
    da.typeListOrigin = res.rows
    // console.log(da.typeListOrigin, 'da.typeListOrigin')
  })
}

const emit = defineEmits(['onMenuItemClicked'])
const closeMenu = () => {
  emit('onMenuItemClicked')
}
</script>

<style lang="scss" scoped>
.sysTitle {
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 26px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08) !important;
  background: #02758a;
}
</style>