

import { defineStore } from "pinia";
import { ref } from "vue";
export const phoneStore = defineStore(
    'phoneStore',
    () => {
        let phoneLog = ref({})
        //控制离开页面的行为
        let leaveVideoFlag = ref(false);


        //手机端收藏
        let favoriteStoreList = ref([]);

        //比对 顾问发消息顾问id 和 左侧选择顾问id
        let CounselorId = ref(null)

        let testNum = ref(2)
        //拨号状态
        let isCallLoading = ref(false);

        //pc 端侧边展开
        let isSide = ref(false)

        //顾问中心手机端侧边栏
        let isConsultingSidebar = ref(false)


        let isPhoneShow = ref(false)
        //搜索框的展示
        let serachShow = ref(false)
        //存放用户个人余额
        let balance = ref(0)

        //顾问信息
        let CounselorMessage = ref({})

        //存放主页在线页面
        let onLineList = ref([])

        //主页全部列表
        let allList = ref([])

        //存放未读信息条数
        let MsgNum = ref({})

        //用户聊天页面存放顾问聊天列表
        let gwMsgList = ref([])
        //顾问
        const gwMsgArr = ref([])
        //用户
        const msgArr = ref([])


        const changeMsgList = () => {
            msgArr.value.forEach(info => {
                if (Array.isArray(gwMsgList.value)) {
                    gwMsgList.value = gwMsgList.value.map(item => {
                        if (item.id && info.key == item.id) {
                            item.msgNum = info.value
                        }
                        return item
                    })
                }
            })
        }

        const changeFavoriteStoreList = () => {
            msgArr.value.forEach(info => {
                if (Array.isArray(favoriteStoreList.value)) {
                    favoriteStoreList.value = favoriteStoreList.value.map(item => {
                        if (item.id && info.key == item.id) {
                            item.msgNum = info.value
                        }
                        return item
                    })
                }
            })
        }


        return {
            phoneLog, leaveVideoFlag, CounselorId, testNum, changeFavoriteStoreList, favoriteStoreList, isCallLoading, isSide, isPhoneShow, balance, CounselorMessage, serachShow, onLineList, allList, MsgNum, gwMsgList, msgArr, changeMsgList, gwMsgArr, isConsultingSidebar
        }
    },


)
