<template>
  <div class="app-wrapper">
    <el-container>
      <el-header>
        <div style="height: 60px; position: fixed; width: 100%; z-index: 1000">
          <navbar />
        </div>
      </el-header>
      <el-container class="main-container">
        <!-- <el-aside width="240px">
          <sidebar id="guide-sidebar" class="sidebar-container"  style=" background-color: #f0efefc6!important;"/>
        </el-aside> -->
        <!-- <div v-if="store.isSide">
          <sidebar />
        </div> -->

        <el-main class="main-cont"> <app-main /></el-main>
      </el-container>
      <Footer></Footer>
    </el-container>
  </div>
  <div class="moblieContent">
    <div
      style="
        height: 60px;
        position: fixed;
        width: 100%;
        z-index: 1000;
        background-color: white;
      "
    >
      <navbar />
    </div>
    
    <app-main />
    <Footer></Footer>
  </div>
</template>
<script setup>
import { watch, onMounted } from 'vue'
import { phoneStore } from '@/store/phone1.js'
import { useStore } from 'vuex'
import AppMain from './components/AppMain.vue'
import Navbar from './components/Navbar.vue'
import Sidebar from './components/Sidebar/index.vue'
import Footer from './components/Footer.vue'

const store = phoneStore()
</script>

<style lang="scss" scoped>
/* 移动端样式 */
@media screen and (max-width: 768px) {
  :deep(.el-main) {
    background: linear-gradient(180deg, lightblue, #fff, #fff);
    padding: 0.5rem !important;
  }
  .moblieContent {
  }
  .app-wrapper {
    display: none;
  }
}

/* PC端样式 */
@media screen and (min-width: 769px) {
  .moblieContent {
    display: none;
  }

  .app-wrapper {
    height: 100%;

    .el-header {
      padding: 0;
    }

    .aw-top {
      display: flex;
      flex-direction: row;
    }

    .main-container {
      display: flex;
      flex-direction: row;
      // padding: 0;
      height: 100%;
      padding: 0.5rem 10rem;
      overflow: auto;
      background: #fff;
      background: linear-gradient(180deg, lightblue, #fff, #fff);

      .sidebar-container {
        // background-color: #10525e !important;
        background-color: rgba(0, 0, 0, 0.3) !important;
        // padding-top: 10px;
      }
    }

    .main-cont {
      padding: 0;
      width: 100%;
      min-width: 790px;
      overflow: hidden;
    }

    .el-footer {
      height: auto;
      padding: 0;
    }
  }
}
</style>
