import axios from 'axios'
import { getToken, removeToken, removeType } from '@/utils/auth'
import router from '@/router/index.js'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
})
service.interceptors.request.use(
  (config) => {
    config.headers.Authorization = getToken() || window.localStorage.getItem('token')
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  (response) => {
    if (response.data.code === 403 || response.data.msg === 'Please login again') {
      removeToken()
      removeType()
      localStorage.clear()

      // setTimeout(() => {
      //   ElMessage.warning('Please log in and try again.')
      // }, 500)

      // const currentPath = router.currentRoute.value.path
      router.push(0)
      // if (currentPath === '/onlineExpert') {
      //   router.push(0)
      // } else {
      //   router.push('/')
      // }
      return Promise.reject(response)
    }
    return response.data
  },
  (error) => {
    return Promise.reject(error)
  }
)
export default service