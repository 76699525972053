<template>
  <div id="app">
    <div>
      <audio id="myAudio">
        <source src="@/assets/test.mp3" type="audio/mp3" />
      </audio>
    </div>
    <router-view />
  </div>
</template>
<script setup>
import { phoneStore } from "./store/phone1";
import { useRouter } from "vue-router";
import { callOpen } from "./components/CallPrompt/messageCall";
import { getToken, getType } from "@/utils/auth";
import { onMounted, inject, h, watch, nextTick } from "vue";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import { page1Store } from "@/store/page1";
import iNotify from "@/utils/inotify.js";
const store1 = phoneStore();
const page1StoreDa = page1Store();
import bus from "vue3-eventbus";
import request from "@/utils/request.js";
const { t } = useI18n();
const socket = inject("socket");
let router = useRouter();
const imgUrl = process.env.VUE_APP_BASE_ImageUrl;
const webMessage = () => {
  if (location.hash.substring(0, 12) == "#/returnHref") {
    return;
  }
  let token = getToken();
  if (token && token.length > 10) {
    const ws = socket("1", token);
    ws.onopen = () => {
      let msg = { type: 0, token: token };
      ws.send(JSON.stringify(msg));
    };
    ws.onmessage = ({ data }) => {
      if (data[0] == "{") {
        let dataParse = JSON.parse(data);
        // console.log(JSON.parse(data), 'data')
        //电话请求
        // console.log(dataParse, "dataParse----------");
        if (dataParse.type == "UnreadUser") {
          store1.MsgNum = JSON.parse(dataParse.message).message;
          let obj = JSON.parse(dataParse.message).message;
          store1.msgArr = Object.entries(obj).map(([key, value]) => ({ key, value }));
          store1.changeFavoriteStoreList();
          console.log(store1.favoriteStoreList, "store1.favoriteStoreList");
          store1.changeMsgList();
          store1.gwMsgList = JSON.parse(dataParse.message).message;
          bus.emit("receiveYh", 2);
        }

        if (dataParse.type == "eliminate") {
          window.localStorage.removeItem("liveRoom");
        }

        if (dataParse.type == "UnreadConsultant") {
          store1.gwMsgArr = JSON.parse(dataParse.message).message;
          bus.emit("receiveGw", 1);
        }

        if (dataParse.type == "call") {
          dataParse.message = JSON.parse(dataParse.message);
          if (dataParse.message.type == "VideoCall") {
            let userName = dataParse.userName;
            let userImg = dataParse.images;
            console.log(imgUrl + userImg, "imgUrl + userImg");
            audioCall();
            console.log("来啦来啦");
            if (window.innerWidth > 768) {
              const iNotifyConfig = {
                title: "Private Session",
                message:
                  userName +
                  " has sent you a private chat request, please go back to Zest Wisdom to view the request.",
                icon: imgUrl + userImg,
                // options: "phone",
              };

              iNotify(iNotifyConfig);
            }

            callOpen(dataParse, router);
          } else if (dataParse.message.type == "appect") {
            ElMessage({ type: "success", message: t("messageCall.accept") });
            store1.phoneLog = dataParse.message;
            console.log(store1.phoneLog, "store1.phoneLog");
            router.push({
              path: "/callPlayer/receive",
              query: {
                type: 0,
                roomName: dataParse.message.roomName,
                roomId: dataParse.message.roomId,
                consultantId: dataParse.message.consultantId,
              },
            });
          } else if (dataParse.message.type == "notification") {
          } else if (dataParse.message.type == "refuse") {
            ElMessage({ type: "warning", message: t("messageCall.refuse") });
            store1.isCallLoading = false;
            bus.emit("callLoadingClose", 1);
          }
        }
        if (dataParse.type == "mailbox") {
          // 接受用户向顾问消息
          bus.emit("onMessage", JSON.parse(dataParse.message));
          let dataParsemsg = JSON.parse(dataParse.message);
          console.log("dataParsemsg", dataParsemsg);
          // You have a new message from
          if (dataParsemsg.userName) {
            ElMessage({
              type: "success",
              message: `${dataParsemsg.userName} sent you a new message!`,
            });
          }
          // console.log('dataParse.message', JSON.parse(dataParse.message))
        }
        if (dataParse.type == "pay") {
          bus.emit("paySuccess", dataParse.data);
        }
        if (dataParse.type == "chat" || dataParse.type == "gift") {
          bus.emit("getChartList", "2");
          page1StoreDa.webSocketMsg = dataParse;
        }
      }
    };
  }
};

onMounted(() => {
  router.beforeEach((to, from, next) => {
    bus.on("appectAuto", () => {
      pauseAndDestroyAudio();
      console.log("关闭音频");
    });
    if (getToken()) {
      getMsg();
    }
    webMessage();
    next();
  });
});

const audioCall = () => {
  let audio = document.getElementById("myAudio");
  audio.setAttribute("loop", "true");
  audio.play(); // 播放音频
  setTimeout(() => {
    pauseAndDestroyAudio();
  }, 45000);
};

const pauseAndDestroyAudio = () => {
  let audio = document.getElementById("myAudio");
  audio.pause(); // 暂停音频
  audio.currentTime = 0; // 将播放位置设置回起始位置
  audio.removeAttribute("loop"); // 移除loop属性，确保不再循环播放
};

const getMsg = async () => {
  let res = await request.get("/system/faChat/activeInfo");
  if (getType() == "1") {
    store1.gwMsgArr.sumUnread = res.data.message.sumUnread;
    console.log(res.data, "res.data.message");
  } else {
    store1.MsgNum.sumUnread = res.data.message.sumUnread;
    console.log(store1.MsgNum.sumUnread, "res.data.message");
  }
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei,
    Arial, sans-serif;
  // overflow: hidden;
}

@media screen and (max-width: 768px) {
  #app {
    overflow: hidden;
  }
}
</style>
