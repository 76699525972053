<template>
  <!-- <div style="width: 240px; position: fixed;left: 0px;"> -->
  <!-- 一级 menu 菜单 -->
  <el-menu
    :default-active="activeMenu"
    router
    text-color="#fff"
    active-text-color="#FFE400"
    style="border-right: none"
    :default-openeds="defaultOpeneds"
    @select="handleSelectMenu"
  >
    <!-- 子集 menu 菜单 -->

    <sidebar-item v-for="item in routes" :key="item.path" :route="item"></sidebar-item>
    <!-- :route="item" -->
    <!-- 控制菜单左侧下方 -->
    <type-list-item
      :typeList="props.typeList"
      @onMenuItemClicked="closeMenu"
    ></type-list-item>
  </el-menu>
  <!-- </div> -->
</template>

<script setup>
import { filterRouters, generateMenus } from "@/utils/route";
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import SidebarItem from "./SidebarItem";
import TypeListItem from "./TypeListItem";
import { getCurrentInstance } from "vue";
const { $t } = getCurrentInstance().proxy;
const props = defineProps({
  typeList: {
    type: Array,
    default: () => [],
  },
});
const router = useRouter();
const routes = computed(() => {
  const filterRoutes = filterRouters(router.getRoutes());
  let arr = generateMenus(filterRoutes);
  arr = arr.filter(
    (item) => item.meta?.mode !== "horizontal" && item.meta?.hide !== true
  );

  /*   arr = arr.filter((item) => item.meta?.mode !== 'horizontal')
    arr = arr.filter((item) => item.meta?.hide !== true) */
  console.log(arr, "arr");
  return arr;
});
// 默认展开的一级菜单
const defaultOpeneds = computed(() => {
  return routes.value.filter((item) => item.children.length).map((item) => item.path);
});
onMounted(() => {});
// 提取Routeitem数组
// const routeArr = ref([])
// watch(
//   () => {
//     return routes
//   },
//   () => {
//     routeArr.value = filterArr(routes.value)
//     console.log(routeArr)
//   },
//   { immediate: true }
// )

// 激活的menu
const activeMenu = computed(() => {
  return router.currentRoute.value.path;
});
// 激活menu时的回调
// const selectFunc = (item) => {
//   const index = findActiveIndex(routeArr.value, item)
//   console.log('selectFunc666666666666666', index)
// }
const handleSelectMenu = (index, indexPath, item, routeResult) => {
  // console.log(index, indexPath, item, routeResult)
};

const emit = defineEmits(["onMenuItemClicked"]);
const closeMenu = () => {
  emit("onMenuItemClicked");
};
</script>

<style lang="scss" scoped>
:deep(.el-menu-item) {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  color: black !important; //菜单的左上部分
}

:deep(.el-sub-menu__title) {
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  font-weight: 700;
  color: black !important;
}
</style>
