

import { defineStore } from "pinia";
import { reactive, toRefs, ref, watch } from "vue";
import piniaPersistConfig from "./persist.js";
export const page1Store = defineStore(
  'page1Store',
  () => {
    const state = reactive({
      username: '',
      token: '' && window.localStorage.getItem('token'),
      webSocketMsg: '123',
      isLoginShow: false,
      isRegisterShow: false,
      userInfo: {}
    })
    const foo = ref("foo")
    const bar = ref("bar")

    return {
      ...toRefs(state),
      foo,
      bar
    }
  },
  {
    //持久化数据,配置数组可以选择需要持久化的数据，如需要全部持久化，使用 persist：true
    persist: [
      piniaPersistConfig(
        'page1Store',
        [
          'token',
          'username',
          'isLoginShow',
          'isRegisterShow',
          'userInfo',
          'foo'
        ], /*'localStorage',不传默认sessionstorage */
      ),
    ],
  }

)
