<template>
  <div class="modal" id="my-modal-2">
    <div class="modal-box">
      <img
        src="@/assets/close_grey.png"
        class="closeBtn"
        @click.stop="closeModalFunction"
      />
      <div class="searchCol">
        <div class="label">Search by topics, expertise, advisor name</div>
        <input class="zw-input" v-model="message" placeholder="  Find advisors" />
      </div>
      <div class="slider-cont">
        <div class="label">Price per minute</div>
          <div >
              <el-slider v-model="minVal" range show-stops :max="maxPerMinVal" />
            </div>
            <div class="minmax-inputs">
              <p step="0.5">${{ minVal[0] }}</p>
              <p step="0.5">${{ minVal[1] }}</p>
            </div>
      </div>
      <button class="btnCont" @click="applyFilterFn(message,minVal)">Apply</button>
    </div>
  </div>
</template>

<script setup>
import CustomMinMaxSlider from "@/components/CustomMinMaxSlider";
import { defineProps, onMounted } from "vue";
import { ref } from "vue";
import { searchList } from "@/api/center.js";
import request from '@/utils/request.js'

const maxPerMinVal = ref(0);
const minVal = ref([0, 0]);
const dataList = ref([]);

const props = defineProps({
  closeModal: {
    type: Function,
  },
  applyFilter: {
    type: Function,
  },
});

onMounted(()=>{
    maxPerMin()
})

const sliderMin = ref(0.99);
const sliderMax = ref(24.99);

const closeModalFunction = () => {
    
  props.closeModal();
  
};

const applyFilterFn = (item,arr) => {
  console.log(item,arr,'item')
  let data1 = {
    minPerMinute:arr[0],
    maxPerMinute:arr[1],
    searchKeys:item
  }
  console.log(data1,'data1')
  // return
  //   ApplySearch()
  props.applyFilter(data1);
};

//获取顾问每分钟最大金额收费
const maxPerMin = async () => {
  const { data } = await request.get("/system/faConsultant/maxPerMinute");
  maxPerMinVal.value = data;
  minVal.value = [0, data];
};

const ApplySearch = async () => {
  console.log(minVal.value, "success");
  let data1 = {
    minPerMinute: minVal.value[0],
    maxPerMinute: minVal.value[1],
  };
  let res = await searchList(data1);
  console.log(res.rows, "resres");
  dataList.value = res.rows
};
</script>

<style lang="scss" scoped>

 :deep(.el-slider__runway) {
    height: 3px;
  }

  :deep(.el-slider__button) {
    border: solid 1px #3f4f5f;
    height: 15px;
    width: 15px;
  }

  :deep(.el-slider__bar) {
    background-color: #303133;
    height: 3px;
  }

  :deep(.el-slider__stop) {
    background-color: #d3d3d3;
    height: 3px;
  }


.modal {
  background-color: white;
  bottom: 0;
  position: absolute;
  width: 100%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  .modal-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // align-items: center;
    padding: 20px;

    .closeBtn {
      width: 20px;
      height: 20px;
      // object-fit: contain;
      align-self: flex-end;
      cursor: pointer;
    }

    .searchCol {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      display: flex;
      padding: 10px 0px;

      .label {
        font-size: 21px;
        padding-bottom: 12px;
        font-weight: 600;
        color: black;
      }
      .zw-input {
        width: 95%;
        height: 40px;
        border: 1px solid #e2e2e2;
        box-shadow: inset 0 1px 4px #ddd;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 700;
        padding: 8px 10px;
      }
    }

    .slider-cont {
      padding: 10px 0px;
      width: 100%;
      .label {
        font-size: 16px;
        padding-bottom: 12px;
        font-weight: 600;
        color: #565656;
      }
      .min-max-cont {
        height: 40px;
        margin-top: 17px;
      }
    }

    .btnCont {
      flex: 1;
      padding: 20px 30px;
      border-radius: 6px;
      cursor: pointer;
      background-color: red;
      color: white;
      border: 1px solid red;
      font-size: 18px;
      font-weight: 800;
    }
  }
}
</style>
