<style lang="scss" scoped>
.tipsGuide {
  white-space:pre-wrap;
  word-break:break-word;
  h3 {
    margin-bottom: 20px;
  }
  .tu {
    position: relative;

    .bigIcon {
      position: absolute;
      right: 60px;
      bottom: 0;
      font-size: 25px;
    }
  }
}
.message-body {
  // width: 800px;
  z-index: 20;

  .message-word {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 8px;
    color: #ffffff;
  }

  .message-content {
    border-radius: 10px;
    width: 100%;
    background: rgb(0, 70, 112);

    .aside-head {
      .message-search {
        height: 74px;
        display: flex;
        align-items: center;
        justify-content: center;
        // border-bottom: 1px solid #8f8f8f;
        padding: 16px 0 16px 16px;
        box-sizing: border-box;

        .search-input {
          background-color: #064442 !important;
          border-radius: 6px;

          :deep(.el-input__wrapper) {
            background-color: #064442;
            box-shadow: none !important;
            color: #fff;
          }

          :deep(.el-input__inner) {
            background-color: #064442;
            color: #fff;
          }
        }
      }

      .person-list {
        height: 50vh;
        // border-right: 1px solid #8f8f8f;
        // border-bottom: 1px solid #8f8f8f;
        padding-top: 10px;
        box-sizing: border-box;
        overflow: auto;

        .person-li-active {
          background-color: #098680;
        }

        .person-li {
          padding: 0 5px;
          height: 42px;
          margin-bottom: 8px;
          color: #e0d8e7;
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            color: #fff;
          }

          .img-bg {
            width: 38px;
            height: 38px;
            background: linear-gradient(
                217deg,
                rgba(249, 146, 13),
                rgba(252, 177, 40) 70.71%
              ),
              linear-gradient(127deg, rgba(251, 162, 27), rgba(241, 170, 48) 70.71%),
              linear-gradient(336deg, rgba(244, 207, 78), rgba(254, 206, 63) 70.71%);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;

            .live-status {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: #086d68;
              display: flex;
              align-items: center;
              justify-content: center;

              .live-start {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #8fc400;
              }

              .live-end {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #f98706;
              }
            }
          }

          img {
            width: 32px;
            height: 32px;
            display: flex;
            border-radius: 50%;
          }

          .person-name {
            width: 70%;
            overflow: hidden;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            margin-left: 10px;
            word-break: break-all;
          }
        }
      }

      .message-kefu {
        display: flex;
        align-items: center;
        height: 80px;
        box-sizing: border-box;
        padding: 0 5px;
        border-right: 1px solid #8f8f8f;

        .kefu-icon {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background-color: #ffe400;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;

          img {
            width: 26px;
            height: 26px;
          }
        }

        font-size: 14px;
        color: #ffffff;
      }
    }

    .main {
      padding: 0;

      .main-head {
        height: 74px;
        // border-bottom: 1px solid #8f8f8f;
        box-sizing: border-box;

        .main-body {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .head-information {
          box-sizing: border-box;
          padding-left: 20px;
          display: flex;
          align-items: center;
          color: #fff;

          .img-bg {
            width: 38px;
            height: 38px;
            background: linear-gradient(
                217deg,
                rgba(249, 146, 13),
                rgba(252, 177, 40) 70.71%
              ),
              linear-gradient(127deg, rgba(251, 162, 27), rgba(241, 170, 48) 70.71%),
              linear-gradient(336deg, rgba(244, 207, 78), rgba(254, 206, 63) 70.71%);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;

            img {
              width: 32px;
              height: 32px;
              border-radius: 50%;
            }

            .live-status {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: #086d68;
              display: flex;
              align-items: center;
              justify-content: center;

              .live-start {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #8fc400;
              }

              .live-end {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #f98706;
              }
            }
          }

          p {
            margin-left: 5px;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }

      .main-start {
        margin-right: 10px;

        p {
          padding: 6px 10px;
          background-color: #ddb60a;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
        }
      }

      .main-content {
        height: 50vh;
        box-sizing: border-box;
        // border-bottom: 1px solid #8f8f8f;

        .main-tip {
          padding-top: 25%;
          color: #fff;
          display: flex;
          flex-direction: column;
          text-align: center;
        }
      }

      .main-footer {
        height: 80px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 15px;

        .footer-input {
          width: 400px;
          height: 48px;

          :deep(.el-textarea__inner) {
            resize: none !important;
            background-color: #064442;
            color: #fff;
          }
        }

        .icon-y {
          font-size: 20px;
          margin-left: 15px;
          cursor: pointer;
        }
      }
    }
  }
}

:deep(.el-textarea__inner:focus) {
  box-shadow: none !important;
}

:deep(.el-textarea__inner) {
  box-shadow: none !important;
}

:deep(.el-input__count) {
  background: transparent !important;
}

.chat-body {
  height: 100%;
  overflow: auto;
  padding: 0 20px;
  box-sizing: border-box;

  .chat-consultant {
    float: left;
    margin-bottom: 10px;
    color: #fff;
    width: 80%;
    height: auto;
    background-color: #064442;
    border-radius: 10px;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: 7px;
      box-sizing: border-box;
      left: -19px;
      height: 20px;
      width: 20px;
      border: 10px solid;
      border-color: transparent #064442 transparent transparent;
    }

    .chat-txt-my {
      padding: 10px;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align: justify;

      .chat-my-img {
        width: 70%;
        height: 70%;
      }
    }
  }

  .chat-in {
    float: right;
    margin-bottom: 10px;
    box-sizing: border-box;
    color: #fff;
    width: 80%;
    height: auto;
    background-color: #064442;
    border-radius: 10px;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: 7px;
      box-sizing: border-box;
      right: -19px;
      height: 20px;
      width: 20px;
      border: 10px solid;
      border-color: transparent transparent transparent #064442;
    }

    .chat-txt-my {
      padding: 10px;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align: justify;

      .chat-my-img {
        width: 70%;
        height: 70%;
      }
    }
  }
}

:deep(.el-upload-list--picture-card) {
  .el-upload-list__item {
    background-color: transparent !important;
    border: none !important;
  }

  .el-upload-list__item-status-label {
    display: none !important;
  }
}

:deep(.el-dialog) {
  background-color: transparent !important;
}

.p1 {
  cursor: not-allowed;
  background-color: #b6b6b6 !important;
  pointer-events: none;
  background-color: #eee;
  border-color: #b6b6b6 !important;
}

.callPhone {
  background: yellow;
  color: black;
  border-color: yellow;
}

.CouponsList {
  background-color: #ffffff;
  .card {
    display: flex;
    height: 80px;
    border: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.3s;
    .left {
      color: red;
      width: 20%;
      text-align: center;
      line-height: 80px;
    }
    .middle {
      flex: 1;

      .middle-up {
        height: 40px;
        display: table-cell;
        vertical-align: bottom;
        font-weight: 900;
        color: #000000;

        // line-height:40px;
      }

      .middle-down {
        color: #39303099;
        height: 40px;
        // line-height:40px;
      }
    }
    .right {
      width: 23%;
    }
  }

  .cardActive {
    background-color: #cccccc;
  }
}
</style>
<template>
  <el-dialog
    class="el-layout"
    v-model="store.isPhoneShow"
    top="5%"
    width="400px"
    @close="closeModelVal"
  >
    <template #header>
      <div class="my-header">
        <div class="message-word">
          <div style="color: #fff; height: 12px"></div>
        </div>
      </div>
    </template>
    <div class="masked-body">
      <div class="message-body">
        <div
          class="message-content"
          :style="{
            width: isWidth ? '400px' : '90%',
            height: '500px',
            padding: '30px',
            margin: 'auto',
          }"
        >
          <div style="display: flex; justify-content: space-between">
            <div style="height: 50px; display: flex"></div>
            <div>
              <h2 style="font-size: 16px; color: #e6dbdb">
                You Have: <span style="color: #ffe400">{{ store.balance }} Credits</span>
              </h2>
            </div>
          </div>
          <div
            style="
              width: 85%;
              margin: auto;
              background-color: #deeff3;
              margin-top: 10px;
              border-radius: 6px;
            "
          >
            <div
              style="
                height: 100px;
                display: flex;
                justify-content: space-around;
                padding-top: 20px;
                margin-left: 5px;
              "
            >
              <el-avatar :size="70" src="https://empty" @error="errorHandler">
                <img :src="ImageUrl + store.CounselorMessage.listImage" />
              </el-avatar>

              <h2
                style="
                  overflow: hidden;
                  width: 70%;
                  color: black;
                  /* text-align: center; */
                  padding-top: 45px;
                  font-size: 20px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ store.CounselorMessage.nickname }}
              </h2>
            </div>
            <!-- <div style="
                            text-align: center;
                            line-height: 50px;
                            ">
                            <span style="color:#02758a">{{ store.CounselorMessage.freeMinutes }}</span> Free Minute
                        </div> -->
            <div style="text-align: center; line-height: 50px">
              <span style="color: #02758a">{{ store.CounselorMessage.perMinute }}</span>
              Credits per minute
            </div>
            <div style="text-align: center; margin-top: 5px">
              <!-- <el-radio :label="true" @change="useFn">coupon</el-radio> -->
              <!-- <el-checkbox  @change="useFn"  v-model="useCoupon" size="large" />  <span style="margin-left:5px;">3-minute free duration coupon</span> -->

              <el-popover
                :placement="direction ? 'top-end' : 'right-end'"
                effect="light"
                :width="400"
                trigger="hover"
              >
                <template #reference>
                  <div style="margin-bottom: 20px">
                    <div style="line-height: 30px; height: 30px; font-size: 12px">
                      <el-icon><InfoFilled /></el-icon>
                      <span style="font-size: 12px; padding-left: 3px"
                        ><el-text tag="ins">Allow webcam</el-text></span
                      >
                    </div>
                  </div>
                </template>

                <div class="tipsGuide" style="color: red">
                  <h3>
                    If you are calling for the first time, please refer to our FAQ on how to grant the necessary permissions.
                  </h3>
                  <div class="tu" style="text-align: center">
                    <!-- <img src="../../assets/yindao.png" alt=""> -->

                    <!-- <el-image
                      style="width: 250px; height: 150px"
                      :src="ImageUrl + store.CounselorMessage.imageDemo"
                      :zoom-rate="1.2"
                      :max-scale="7"
                      :min-scale="0.2"
                      :preview-src-list="[ImageUrl + store.CounselorMessage.imageDemo]"
                      :initial-index="4"
                      fit="cover"
                    />
                    <div class="bigIcon">
                      <el-icon><CirclePlus /></el-icon>
                    </div> -->
                  </div>
                </div>
              </el-popover>

              <el-button @click="openCoupon" type="primary" round>Use coupons</el-button>
            </div>

            <div v-if="oneCard.id" style="text-align: center; margin-top: 15px">
              <el-checkbox @change="useFn" v-model="useCoupon" size="large" />
              <span style="margin-left: 5px"
                >{{ oneCard.discountTime }}-minute free duration coupon</span
              >
            </div>
            <div
              :style="{
                width: store.isCallLoading ? '120px' : '80px',
                margin: 'auto',
                marginTop: '20px',
              }"
            >
              <div class="main-start">
                <el-button
                  :style="{
                    background: store.isCallLoading ? 'rgb(182,182,182)' : 'yellow',
                    color: 'black',
                    borderColor: store.isCallLoading ? 'rgb(182,182,182)' : 'yellow',
                  }"
                  type="primary"
                  :loading="store.isCallLoading"
                  @click="CallClick"
                  :disabled="
                    store.CounselorMessage.status == 0 ||
                    store.CounselorMessage.status == 3
                      ? true
                      : false
                  "
                  :class="
                    store.CounselorMessage.status == 0 ||
                    store.CounselorMessage.status == 3
                      ? 'p1'
                      : ''
                  "
                  >{{ callText }}</el-button
                >
              </div>
            </div>

            <div
              style="
                padding: 5px;
                text-align: center;
                margin-top: 30px;
                color: rgb(0, 70, 112);
              "
            >
              Calls lasting less than one minute are counted as one minute
            </div>
            <!-- <div>consultantId:{{ store.phoneLog.consultantId }}</div>
            <div>roomId:{{ store.phoneLog.roomId }}</div>
            <div>roomName:{{ store.phoneLog.roomName }}</div>
            <div>type:{{ store.phoneLog.type }}</div> -->
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="uploadStatus"
      title="Please select an image to upload"
      width="30%"
      :before-close="handleClose"
    >
      <div
        style="width: 100%; display: flex; align-items: center; justify-content: center"
      >
        <ImageUpload
          :imgUrl="messageImgVal"
          :tipTxt="false"
          :limit="1"
          @update:imgUrl="updateimgUrl"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="uploadStatus = false">Cancel</el-button>
          <el-button type="primary" @click="imgConfirm"> Confirm </el-button>
        </span>
      </template>
    </el-dialog>
  </el-dialog>

  <el-dialog
    v-model="couponsTips"
    title="Coupons"
    :width="couponsTipsWidth"
    @close="couponsTips = false"
  >
    <div class="CouponsList">
      <div
        :class="item.flag ? 'cardActive' : ''"
        v-for="(item, index) in NoUseCouponsList"
        :key="item.id"
        @click="onCard(item, index)"
      >
        <div class="card">
          <div class="left">${{ item.discountTime }}</div>
          <div class="middle">
            <div class="middle-up">{{ item.discountTime }}-minute free duration</div>
            <div class="middle-down">{{ zhTime(item.endTime) }}</div>
          </div>
          <div class="right">
            <el-button style="margin-top: 25px" disabled>Not used</el-button>
          </div>
        </div>
      </div>
      <div v-if="NoUseCouponsList.length == 0">
        <h2>No coupons available</h2>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="couponsTips = false">Cancel</el-button>
        <el-button type="primary" @click="sureOk"> Confirm </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { InfoFilled, CirclePlus } from "@element-plus/icons-vue";
import { phoneStore } from "../../store/phone1";
import {
  ref,
  defineProps,
  watch,
  inject,
  onMounted,
  nextTick,
  onUnmounted,
  watchEffect,
} from "vue";
import request from "@/utils/request.js";
import { sendWsMsg } from "@/api/messageCall";
import ImageUpload from "@/components/ImageUpload/index.vue";
import { ElMessage } from "element-plus";
import { getType } from "@/utils/auth";
import bus from "vue3-eventbus";

const callText = ref("Call Now");
const couponsTipsWidth = ref("");
const oneCard = ref([]); //带出来的单个优惠券
const NoUseCouponsList = ref([]);
const couponsTips = ref(false);
const useCoupon = ref(true);
const store = phoneStore();
const socket = inject("socket");
const messageImgVal = ref("");
const search = ref("");
const messageVal = ref("");
const showModel = ref(false);
const liActive = ref(null);
const liActiveid = ref(null);
const messageList = ref([]);
const msglist = ref("");
const tuijianList = ref([]);
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const liActiveTJ = ref(null);
const liActiveidTJ = ref(null);
const upList = ref([]);
const callTimes = ref(null);
const callLoading = ref(false);
const openid = ref("");
const isCenter = ref(false);
const isWidth = ref(false);

const direction = ref(false);

const useFn = (e) => {
  console.log(e);
};

watch(
  () => store.isCallLoading,
  (n) => {
    if (n == true) {
      callText.value = "Requesting";
    } else {
      callText.value = "Call Now";
    }
  }
);

//确定使用弹窗
const sureOk = () => {
  let useFlag = NoUseCouponsList.value.some((item) => item.flag);

  if (!useFlag) {
    couponsTips.value = false;
    return;
  } else {
    oneCard.value = NoUseCouponsList.value.filter((item) => item.flag)[0];
    console.log(oneCard.value, "oneCard.value");
    couponsTips.value = false;
    return;
  }
};

const onCard = (item, index) => {
  NoUseCouponsList.value.forEach((i, o) => {
    i.flag = false;
    if (index == o) {
      i.flag = true;
    } else {
      i.flag = false;
    }
  });

  console.log(NoUseCouponsList.value);
};

const openCoupon = async () => {
  let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
  let userId = userInfo.id;
  console.log(userId, "store.CounselorMessage.id");

  let res = await request.get("/system/faUserDiscount/discountList?userId=" + userId);

  if ((res.code = "200")) {
    NoUseCouponsList.value = res.data.filter((item) => item.discountType == "0");
    NoUseCouponsList.value = NoUseCouponsList.value.map((info) => {
      return {
        ...info,
        flag: false,
      };
    });

    console.log(NoUseCouponsList.value, "couponsList.value");
  }

  couponsTips.value = true;
};

//转时间函数
const zhTime = (val) => {
  // 创建一个 Date 对象，解析 ISO 8601 日期时间字符串
  const date = new Date(val);

  // 从 Date 对象中提取年、月、日、时、分和秒
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day}  ${hours}:${minutes}:${seconds}`;
};

onMounted(() => {
  if (window.innerWidth > 768) {
    couponsTipsWidth.value = "30%";
    direction.value = false;
    isWidth.value = true;
  } else {
    isWidth.value = false;
    couponsTipsWidth.value = "90%";
    direction.value = true;
  }
});
onUnmounted(() => {
  clearTimeout(callTimes.value);
});

const emit = defineEmits(["showModelValChange"]);
const CallClick = async () => {
  if (store.isCallLoading == true) {
    return;
  }
  let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
  let userId = userInfo.id;
  //是否使用了优惠卷
  if (oneCard.value.id && useCoupon) {
    let data = {
      id: oneCard.value.id,
      userId,
      discountId: oneCard.value.discountId,
    };
    let res = await request.post("/system/faUserDiscount/useCoupons", data);
  } else {
    let data = {
      userId,
    };
    let res = await request.post("/system/faUserDiscount/useCoupons", data);
    console.log(res, "未使用优惠券");
  }

  let msg = {
    type: "VideoCall",
  };
  let res = await sendWsMsg(store.CounselorMessage.id, 2, msg);
  store.isCallLoading = true;
  if (res.code == "200") {
    setTimeout(() => {
      store.isCallLoading = false;
      clearTimeout(callTimes.value);
    }, 45 * 1000);
    ElMessage({
      type: "success",
      message: "call",
    });
    callTimes.value = setTimeout(() => {
      store.isCallLoading = false;
      ElMessage({
        type: "warning",
        message: "Not Connected",
      });

      clearTimeout(callTimes.value);
    }, 45 * 1000);
  }
  if (res.code == "500") {
    setTimeout(() => {
      store.isCallLoading = false;
    }, 3000);
    ElMessage({
      type: "error",
      message: res.msg,
    });
  }
};
function closeModelVal() {
  clearTimeout(callTimes.value);
  // emit('showModelValChange', false)
  store.isPhoneShow = false;
}

async function getUpList() {
  // /* &isRecommend=true */&hasChat=true
  const { code, rows } = await request.get(
    "/system/faConsultant/list?pageSize=10000&isRecommend=true"
  );
  if (code == 200) {
    tuijianList.value = rows;
  }
}
async function searchList() {
  const { code, rows } = await request.get(
    `/system/faConsultant/list?pageSize=10000${
      search.value ? "&nickname=" + search.value : "&hasChat=true"
    }`
  );
  if (code == 200) {
    upList.value = rows;
  }
  console.log(rows);
}
async function getChatInfo(targetId) {
  console.log(targetId, "phone-----idididi");
  const { code, data } = await request.get(
    "/system/faChat/info?pageSize=10000000&targetId=" + targetId
  );
  if (code == 200) {
    if (data) {
      // data.infos.forEach(item => {
      //     item.userType = '2'
      // })
      messageList.value = data.infos;
    } else {
      messageList.value = [];
    }
    setTimeout(() => {
      msgScroll();
    }, 200);
    console.log("messageList", messageList.value);
  } else {
    messageList.value = [];
    ElMessage("Server error.");
  }
}
async function postfaChatsend(type, e) {
  if (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  }
  let data;
  if (type == 1) {
    if (!messageVal.value) {
      ElMessage("Input cannot be empty.");
      return;
    }
    data = {
      targetId: liActiveid.value || liActiveidTJ.value,
      message: messageVal.value,
    };
  }
  if (type == 2) {
    if (!messageImgVal.value) {
      ElMessage("Please select an image to upload.");
      return;
    }
    data = {
      targetId: liActiveid.value || liActiveidTJ.value,
      imgUrl: messageImgVal.value,
    };
  }
  const { code } = await request.post("/system/faChat/send", data);
  if (code == 200) {
    if (type == 1) {
      messageVal.value = "";
    }
    if (type == 2) {
      messageImgVal.value = null;
      uploadStatus.value = false;
    }
    getChatInfo(liActiveid.value || liActiveidTJ.value);
    // getChatInfo(liActiveid.value)
  } else {
    ElMessage("Server error.");
  }
}
function msgScroll() {
  if (msglist.value && msglist.value.scrollHeight) {
    msglist.value.scrollTop = msglist.value.scrollHeight;
  }
}
const uploadStatus = ref(false);
function handleClose() {
  uploadStatus.value = false;
}
function uploadImage() {
  uploadStatus.value = true;
  console.log("上传图片");
}
function updateimgUrl(e) {
  messageImgVal.value = e;
}
function imgConfirm() {
  postfaChatsend(2);
}
</script>
