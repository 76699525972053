<style lang="scss" scoped>
//pc端样式
@media screen and (min-width: 769px) {
  .navbar {
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding: 0px 10rem;
    background: #ffffff;

    .mobile {
      display: none;
    }

    .left-menu {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-left: 0px;
      cursor: pointer;
      display: flex;

      .zwIcon {
        padding-right: 10px;
      }
      .zwLogo {
        .mainLogo {
          height: 70px;
        }
      }

      .sysTitle {
        display: flex;

        img {
          height: 52px;
        }
      }
    }

    .login-all {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .login-right {
        font-size: 18px;
        padding: 10px 18px;
        cursor: pointer;
        margin: 0 10px;
        font-weight: 500;
      }

      .button {
        font-size: 18px;
        font-weight: 500;
        padding: 10px 18px;
        border-radius: 4px;
        background-color: rgb(189, 219, 228);
        border: 1px solid #ffff;
        cursor: pointer;
        margin: 0 5px;
        box-shadow: rgb(90, 181, 209) 0px -3px 0px inset;
      }

      .right-menu {
        display: flex;
        align-items: center;
        flex-direction: "row";
        justify-content: "space-between";
        width: 100%;
        flex: 1;

        .advisoryCont {
          padding: 8px 18px;
          border-radius: 4px;
          cursor: pointer;
          margin-right: 10px;
          background: lightblue;

          .advisoryText {
            color: black;
            font-size: 12px;
          }
        }
      }

      .xiaoxi {
        position: relative;
      }

      .right-icon {
        padding: 0 3px;
        margin-right: 8px;
        cursor: pointer;

        .xiaoxiItem {
          position: absolute;
          top: -13px;
          right: -15px;
        }
      }

      .pay-right {
        margin-right: 8px;
        cursor: pointer;
        font-size: 12px;
        color: aliceblue;
      }

      .login-right1 {
        font-size: 12px;
        padding: 8px 18px;
        cursor: pointer;
        background-color: white;

        span {
          margin-right: 6px;
        }

        .left-icon {
          margin-right: 4px;
        }

        .right-icon {
          margin-left: 10px;
          color: #080808;
        }
      }

      .login-button {
        color: #055f05;
        font-weight: 500;
        background: #eaee03;
      }

      ::v-deep .avatar-container {
        width: 162px;
      }

      .language {
        font-size: 14px;
        color: #ffffff;
        margin: 0 25px;
        cursor: pointer;
      }
    }

    .masked {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.75);
      z-index: 100;
      // display: none;

      .masked-all {
        width: 430px;
        height: 367px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      .masked-word {
        display: flex;
        justify-content: space-between;
        font-size: 22px;
        margin: 8px;
        color: #ffffff;
      }

      .masked-login {
        background: #086d68;
        height: 400px;
        padding-left: 3px;

        .el-form-item {
          margin-top: 20px;
          margin-left: 15px;
          margin-bottom: 28px;
        }

        .masked-check {
          margin-left: 15px;
          color: #ffff;
          font-size: 12px;
          margin-top: 8px;
        }

        .send-login {
          display: flex;
          margin-left: 20px;
        }

        .google-icon {
          width: 20px;
          height: 20px;
        }

        .masked-foot {
          margin-left: 39px;
          margin-top: 8px;

          p {
            color: #ffff;
            font-size: 12px;
            margin-top: 8px;
          }

          span {
            color: #eaee03;
            cursor: pointer;
          }
        }
      }

      .masked-reginster {
        padding-top: 20px;
        background: #086d68;
        height: 410px;
        padding-left: 20px;

        .regester-foot {
          margin-top: 15px;
          // text-align: center;
          color: #ffffff;
          font-size: 12px;
          line-height: 18px;
          padding: 5px 47px 25px 30px;

          span {
            margin-left: 5px;
            color: #eaee03;
            cursor: pointer;
          }
        }
      }
    }

    .masked-new {
      background: rgba(0, 0, 0, 0.75);
      justify-content: center;
      align-items: center;
      position: fixed;
      width: 100%;
      height: 100%;
      display: flex;
      inset: 0px;
      overflow: auto;
      z-index: 1000;

      .masked-all-new {
        max-width: 920px;
        border-radius: 8px;
        flex-direction: column;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        .masked-back-button {
          position: relative;
          top: 27px;
          left: 23px;
          z-index: 10;
          display: flex;
          align-self: flex-start;
          width: 90%;
          height: 5%;
          .masked-back-button-img {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
        }
        .masked-content {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          min-width: 315px;
          max-width: 315px;
          padding-top: 30px;
          padding-bottom: 45px;
          .masked-content-logo {
            max-width: 200px;
            max-height: 200px;
            width: 65px;
            height: 65px;
            object-fit: contain;
          }
          .masked-input {
            width: 100%;
            min-width: 315px;
            border: 1px solid #cecece;
            border-radius: 4px;
            background-color: #fff;
            text-align: center;
            height: 44px;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 500;
          }
          .keep-login {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            font-size: 13px;
            .keep-login-word {
              margin-left: 8px;
            }
          }
          .login-and-join-button {
            outline: none;
            border: none;
            cursor: pointer;
            background: none;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 44px;
            border-radius: 4px;
            background-color: rgb(90, 181, 209);
            margin-top: 10px;
            font-size: 16px;
            font-weight: 900;
            color: #fff;
          }
          .forgot-password-button {
            border: none;
            padding: 0;
            cursor: pointer;
            justify-content: center;
            width: 100%;
            margin-top: 20px;
            font-size: 13px;
            font-weight: 700;
            color: rgb(90, 181, 209);
            background: #fff;
          }
          .connect-with-word_login {
            font-size: 12px;
            font-weight: 500;
            margin-top: 24px;
            color: #bfbfbf;
          }
          .connect-with-word_register {
            font-size: 12px;
            font-weight: 500;
            margin-top: 15px;
            color: #bfbfbf;
          }
          .google-facebook-buttons-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            padding: 5px;
            .facebook-button {
              width: 400px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              cursor: pointer;
              align-items: center;
              padding: 7px 0;
              border-radius: 4px;
              margin-right: 5px;
              font-size: 14px;
              color: #3c4043;
              background-color: #fff;
              border: 1px solid #dadce0;
              flex: 1;
              img {
                padding-right: 7px;
              }
            }
            .google-button {
              width: 150px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              cursor: pointer;
              align-items: center;
              padding: 7px 0;
              border-radius: 4px;
              font-size: 14px;
              color: #3c4043;
              background-color: white;
              border: 1px solid #dadce0;
              flex: 1;
              img {
                padding-right: 7px;
              }
            }
          }
          .switch-page-word-link {
            border: none;
            padding: 0;
            cursor: pointer;
            justify-content: center;
            margin-top: 20px;
            font-size: 13px;
            font-weight: 400;
            color: rgb(90, 181, 209);
            background: #fff;
          }
          .register-description {
            display: block;
            text-align: center;
            margin-top: 10px;
            font-size: 10px;
            width: 100%;
            color: #a3a3a3;
            .faq_and_tc_word {
              color: rgb(90, 181, 209);
              font-weight: 500;
            }
          }
          .reset-password-word {
            text-align: center;
            margin-top: 1em;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            width: 100%;
            font-weight: bold;
          }
          .reset-password-word-description {
            text-align: center;
            margin-top: 1em;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            width: 100%;
            color: #a3a3a3;
          }
          .register-code-description {
            margin-top: 1em;
            text-align: center;
            font-size: 15px;
            color: #a3a3a3;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .login-form {
      position: relative;
      padding: 16px 16px 16px 27px;
      margin: 0 auto;
      overflow: hidden;

      :deep(.el-form-item) {
        height: 50px;
        color: #454545;
      }

      .login-input {
        :deep(.el-input__wrapper) {
          background-color: transparent;

          .el-input__inner {
            background-color: transparent;
            border: 1px solid #eaee03;
            color: #ffffff;
            // margin-left: 18px;
          }
        }
      }

      :deep(.el-input) {
        display: inline-block;
        height: 47px;
        width: calc(100% - 34px);

        .el-input__wrapper {
          padding: 0;
          border: none;
          width: 100%;
          box-shadow: none;
        }

        input {
          height: 47px;
          width: 100%;
          /* background: $inputBg !important; */
          opacity: 1;
          background-color: rgba(255, 255, 255, 0.247);
          padding: 12px 5px 12px 15px;
        }
      }
    }

    .pay-all {
      width: 600px;
      margin: auto;
      margin-top: 60px;

      .pay-word {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        margin: 8px 0;
        color: #ffffff;
      }
    }

    .pay-content {
      border-radius: 10px;
      width: 597px;
      // height: 350px;
      background-color: #086d68;

      .pay-list {
        padding: 20px 20px 20px 25px;
        display: flex;
        //  justify-content:space-between;
        flex-wrap: wrap;
        //  margin-left:10px;

        .checkIndex {
          width: 130px;
          height: 200px;
          background-color: #5d8283;
          list-style: none;
          color: #eaee03;
          text-align: center;
          padding: 20px;
          box-sizing: border-box;
          margin-right: 8px;
          cursor: pointer;
          margin-top: 10px;

          .pay-shu {
            font-size: 24px;
            line-height: 15px;
            margin-top: 10px;
          }

          .pay-words {
            line-height: 30px;
            margin-bottom: 15px;
          }

          .pay-buy {
            line-height: 20px;
            padding: 10px;
            background-color: #eaee03;
            color: #080808;
            font-size: 12px;
            margin-bottom: 25px;
            border-radius: 2px;
          }

          .pay-money {
            line-height: 30px;
          }
        }

        // li:hover {
        //   background-color: #a3acac;
        // }

        .selected {
          background-color: #5a5e5e;
        }
      }

      .customer-pay {
        padding: 6px 10px;

        .cust-pay {
          display: flex;
          align-items: center;

          .title {
            font-size: 14px;
            display: table-cell;
            vertical-align: middle;
          }

          .line {
            height: 1px;
            margin: 0 0 0 15px;
            width: 500px;

            span {
              display: block;
              height: 1px;
              position: relative;
              width: 500px;
              background: #928e8e;
            }
          }
        }
      }

      .pay-typle {
        text-align: center;
        padding: 15px 0;
        font-size: 12px;

        p {
          color: #ffffff;
        }

        span {
          margin-left: 5px;
          color: #ddb60a;
          cursor: pointer;
        }

        .pay-yue {
          width: 80px;
          line-height: 30px;
          background-color: #fff;
          margin: auto;
          margin-top: 8px;
          cursor: pointer;
          border-radius: 16px;

          .paypal-icon {
            display: block;
            height: 100%;
            width: 100%;
            padding: 5px 10px;
            box-sizing: border-box;
          }
        }
      }

      .pay-footer {
        text-align: center;
        font-size: 12px;
        color: #ffffff;
        padding: 10px 0 20px 10px;
        border-top: 1px solid #02758a;
      }
    }
  }

  :deep(.el-layout) {
    box-shadow: none;
    background-color: transparent;

    .el-dialog__body {
      padding: 0;
    }
  }

  .size_class {
    z-index: 15;
    position: fixed;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
  }

  .side_left {
    background-color: white;
    display: flex;
    padding: 20px;
    width: 15%;
    height: 100%;
    position: fixed;
    top: 0px;
    flex-direction: column;
    z-index: 1000;
    // .left_button:hover {
    //   background-color: #8f63ec;
    // }

    .closeBtn {
      width: 20px;
      height: 20px;
      object-fit: contain;
      align-self: flex-end;
      cursor: pointer;
    }

    .menuCont {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .menuCont_bottom {
        position: absolute;
        bottom: 50px;
        .left_button {
          color: black;
          text-align: left;
          padding: 5px 20px;
          cursor: pointer;
          font-weight: 700;
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .navbar {
    padding-left: 15px;
    padding-right: 15px;
  }
}

//移动端样式
@media screen and (min-width: 320px) and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 3px; /* 滚动条宽度 */
  }
  :deep(.el-drawer__header) {
    margin-bottom: 0px;
  }
  :deep(.el-drawer__body) {
    margin-bottom: 20px;
  }
  :deep(.el-drawer) {
    background: #ffffff !important;
  }
  :deep(.el-overlay-dialog) {
    overflow: hidden;
  }
  .pc {
    display: none !important;
  }
  //用户登录
  .navbar {
    height: 64px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    display: flex;

    .mobleSet {
      h2 {
        height: 40px;
        background-color: rgba(240, 239, 239, 0.776);
        line-height: 40px;
        font-size: 15px;
        border-bottom: 1px #a77e7e solid;
      }

      .mobleSet-mid {
        .pActive {
          border-bottom: none !important;
        }

        .userMasg {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px #a77e7e solid;
          font-size: 13px;
          height: 40px;
          line-height: 40px;

          .p1 {
            width: 35%;
          }

          .p2 {
            flex-grow: 1;
            text-align: right;
            line-height: 39px;
          }
        }
      }
    }

    .rechargeList {
      .list-box {
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
        border-bottom: 1px solid #eee;
        line-height: 30px;

        div {
          width: 50%;
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .login > Button {
      background-color: rgb(122, 74, 226);
      border-style: none;
      border-radius: 8px;
      box-shadow: rgb(92, 48, 187) 0px -2px 0px inset;
      font-size: 12px;
      padding: 0 10px;
      height: 38px;
      color: #fff;
      cursor: pointer;
    }

    .left-menu {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      display: flex;

      .zwLogo {
        .mainLogo {
          height: 45px;
        }
      }
    }

    .login-all {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      .login-right {
        font-size: 16px;
        padding: 10px 18px;
        cursor: pointer;
        font-weight: 500;
      }

      .button {
        font-size: 16px;
        font-weight: 500;
        padding: 10px 18px;
        border-radius: 4px;
        background-color: rgb(189, 219, 228);
        border: 1px solid #ffff;
        cursor: pointer;
      }

      .right-menu {
        display: flex;
        align-items: center;
        flex-direction: "row";
        justify-content: "space-between";
        width: 100%;
        flex: 1;

        .advisoryCont {
          padding: 8px 18px;
          border-radius: 4px;
          cursor: pointer;
          margin-right: 10px;
          background: lightblue;

          .advisoryText {
            color: black;
            font-size: 12px;
          }
        }
      }
    }

    .masked {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.75);
      z-index: 100;

      .masked-all {
        width: 100%;
        height: 367px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      .masked-word {
        display: flex;
        justify-content: space-between;
        font-size: 22px;
        margin: 8px;
        color: #ffffff;
      }

      .masked-login {
        background: #086d68;
        height: 400px;
        padding-left: 3px;

        .el-form-item {
          margin-top: 20px;
          margin-left: 15px;
          margin-bottom: 28px;
        }

        .masked-check {
          margin-left: 15px;
          color: #ffff;
          font-size: 12px;
          margin-top: 8px;
        }

        .send-login {
          display: flex;
          margin-left: 20px;
        }

        .google-icon {
          width: 20px;
          height: 20px;
        }

        .masked-foot {
          margin-left: 39px;
          margin-top: 8px;

          p {
            color: #ffff;
            font-size: 12px;
            margin-top: 8px;
          }

          span {
            color: #eaee03;
            cursor: pointer;
          }
        }
      }

      .masked-reginster {
        padding-top: 20px;
        background: #086d68;
        height: 410px;
        padding-left: 20px;

        .regester-foot {
          margin-top: 15px;
          // text-align: center;
          color: #ffffff;
          font-size: 12px;
          line-height: 18px;
          padding: 5px 47px 25px 30px;

          span {
            margin-left: 5px;
            color: #eaee03;
            cursor: pointer;
          }
        }
      }
    }

    .masked-new {
      background: rgba(0, 0, 0, 0.75);
      justify-content: center;
      align-items: flex-end;
      position: fixed;
      width: 100%;
      height: 100%;
      display: flex;
      inset: 0px;
      overflow: auto;
      z-index: 1000;

      .masked-all-new {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        flex-direction: column;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        .masked-back-button {
          position: relative;
          top: 27px;
          left: 23px;
          z-index: 10;
          display: flex;
          align-self: flex-start;
          width: 90%;
          height: 5%;
          .masked-back-button-img {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
        }
        .masked-content {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          max-width: 280px;
          padding-top: 30px;
          padding-bottom: 45px;
          .masked-content-logo {
            max-width: 200px;
            max-height: 200px;
            width: 65px;
            height: 65px;
            object-fit: contain;
          }
          .masked-input {
            width: 100%;
            border: 1px solid #cecece;
            border-radius: 4px;
            background-color: #fff;
            text-align: center;
            height: 44px;
            min-width: 280px;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 500;
          }
          .keep-login {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            font-size: 13px;
            .keep-login-word {
              margin-left: 8px;
            }
          }
          .login-and-join-button {
            outline: none;
            border: none;
            cursor: pointer;
            background: none;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 44px;
            border-radius: 4px;
            background-color: rgb(90, 181, 209);
            margin-top: 10px;
            font-size: 16px;
            font-weight: 900;
            color: #fff;
          }
          .forgot-password-button {
            border: none;
            padding: 0;
            cursor: pointer;
            justify-content: center;
            width: 100%;
            margin-top: 20px;
            font-size: 13px;
            font-weight: 700;
            color: rgb(90, 181, 209);
            background: #fff;
          }
          .connect-with-word_login {
            font-size: 12px;
            font-weight: 500;
            margin-top: 24px;
            color: #bfbfbf;
          }
          .connect-with-word_register {
            font-size: 12px;
            font-weight: 500;
            margin-top: 15px;
            color: #bfbfbf;
          }
          .google-facebook-buttons-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            min-width: 280px;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            padding: 5px;
            .facebook-button {
              width: 420px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              cursor: pointer;
              align-items: center;
              padding: 7px 0;
              border-radius: 4px;
              margin-right: 5px;
              font-size: 14px;
              color: #3c4043;
              background-color: #fff;
              border: 1px solid #dadce0;
              flex: 1;
              img {
                padding-right: 7px;
              }
            }
            .google-button {
              width: 130px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              cursor: pointer;
              align-items: center;
              padding: 7px 0;
              border-radius: 4px;
              font-size: 14px;
              color: #3c4043;
              background-color: white;
              border: 1px solid #dadce0;
              flex: 1;
              img {
                padding-right: 7px;
              }
            }
          }
          .switch-page-word-link {
            border: none;
            padding: 0;
            cursor: pointer;
            justify-content: center;
            margin-top: 20px;
            font-size: 13px;
            font-weight: 400;
            color: rgb(90, 181, 209);
            background: #fff;
          }
          .register-description {
            display: block;
            text-align: center;
            margin-top: 10px;
            font-size: 10px;
            width: 100%;
            color: #a3a3a3;
            .faq_and_tc_word {
              color: rgb(90, 181, 209);
              font-weight: 500;
            }
          }
          .reset-password-word {
            text-align: center;
            margin-top: 1em;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-size: 15px;
            font-weight: bold;
          }
          .reset-password-word-description {
            text-align: center;
            margin-top: 1em;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-size: 15px;
            color: #a3a3a3;
          }
          .register-code-description {
            margin-top: 1em;
            text-align: center;
            font-size: 15px;
            color: #a3a3a3;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .login-form {
      position: relative;
      padding: 16px 16px 16px 27px;
      margin: 0 auto;
      overflow: hidden;

      :deep(.el-form-item) {
        height: 50px;
        color: #454545;
      }

      .login-input {
        :deep(.el-input__wrapper) {
          background-color: transparent;

          .el-input__inner {
            background-color: transparent;
            border: 1px solid #eaee03;
            color: #ffffff;
            // margin-left: 18px;
          }
        }
      }

      :deep(.el-input) {
        display: inline-block;
        height: 47px;
        width: calc(100% - 34px);

        .el-input__wrapper {
          padding: 0;
          border: none;
          width: 100%;
          box-shadow: none;
        }

        input {
          height: 47px;
          width: 100%;
          /* background: $inputBg !important; */
          opacity: 1;
          background-color: rgba(255, 255, 255, 0.247);
          padding: 12px 5px 12px 15px;
        }
      }
    }

    .pay-all {
      width: 359px;
      margin: auto;
      margin-top: 60px;

      .pay-word {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        margin: 8px 0;
        color: #ffffff;
      }
    }

    .pay-content {
      border-radius: 10px;
      width: 359px;
      background-color: #086d68;

      .pay-list1 {
        padding: 20px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;

        .checkIndex {
          width: 100%;
          height: 50px;
          line-height: 50px;
          background-color: #5d8283;
          list-style: none;
          color: #eaee03;
          text-align: center;
          margin-right: 8px;
          cursor: pointer;
          display: flex;
          margin-bottom: 10px;

          .pay-shu {
            width: 100px;
            font-size: 14px;
            text-align: right;
          }

          .pay-buy {
            width: 80px;
            line-height: 30px;
            padding: 10px 5px;
            background-color: #eaee03;
            color: #080808;
            font-size: 12px;
            border-radius: 2px;
          }

          .pay-money {
            width: 40%;
            font-size: 14px;
          }
        }

        .selected {
          background-color: #5a5e5e;
        }
      }

      .customer-pay {
        padding: 6px 10px;

        .cust-pay {
          display: flex;
          align-items: center;

          .title {
            font-size: 14px;
            display: table-cell;
            vertical-align: middle;
          }

          .line {
            height: 1px;
            margin: 0 0 0 15px;
            width: 500px;

            span {
              display: block;
              height: 1px;
              position: relative;
              width: 500px;
              background: #928e8e;
            }
          }
        }
      }

      .pay-typle {
        text-align: center;
        padding: 15px 0;
        font-size: 12px;

        p {
          color: #ffffff;
        }

        span {
          margin-left: 5px;
          color: #ddb60a;
          cursor: pointer;
        }

        .pay-yue {
          width: 80px;
          line-height: 30px;
          background-color: #fff;
          margin: auto;
          margin-top: 8px;
          cursor: pointer;
          border-radius: 16px;

          .paypal-icon {
            display: block;
            height: 100%;
            width: 100%;
            padding: 5px 10px;
            box-sizing: border-box;
          }
        }
      }

      .pay-footer {
        text-align: center;
        font-size: 12px;
        color: #ffffff;
        padding: 10px 0;
        border-top: 1px solid #02758a;
      }
    }
  }

  :deep(.el-layout) {
    box-shadow: none;
    background-color: transparent;

    .el-dialog__body {
      padding: 0;
    }
  }

  .computer {
    display: none;
  }

  .size_class {
    z-index: 15;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .side_left {
    background-color: white;
    display: flex;
    padding: 10px;
    width: 60%;
    height: 100%;
    position: fixed;
    top: 0px;
    flex-direction: column;
    z-index: 1000;

    .closeBtn {
      width: 20px;
      height: 20px;
      object-fit: contain;
      align-self: flex-end;
      cursor: pointer;
    }

    .menuCont {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .menuCont_bottom {
        position: absolute;
        bottom: 50px;
        .left_button {
          color: black;
          text-align: left;
          padding: 5px 20px;
          cursor: pointer;
          font-weight: 700;
        }
      }
    }
  }
}

@media screen and (max-width: 319px) {
  //用户登录
  .navbar {
    height: 64px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    display: flex;

    .mobleSet {
      h2 {
        height: 40px;
        background-color: rgba(240, 239, 239, 0.776);
        line-height: 40px;
        font-size: 15px;
        border-bottom: 1px #a77e7e solid;
      }

      .mobleSet-mid {
        .pActive {
          border-bottom: none !important;
        }

        .userMasg {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px #a77e7e solid;
          font-size: 13px;
          height: 40px;
          line-height: 40px;

          .p1 {
            width: 35%;
          }

          .p2 {
            flex-grow: 1;
            text-align: right;
          }
        }
      }
    }

    .login > Button {
      background-color: rgb(122, 74, 226);
      border-style: none;
      border-radius: 8px;
      box-shadow: rgb(92, 48, 187) 0px -2px 0px inset;
      font-size: 12px;
      padding: 0 10px;
      height: 38px;
      color: #fff;
      cursor: pointer;
    }

    .left-menu {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      display: flex;

      .zwLogo {
        .mainLogo {
          height: 45px;
        }
      }
    }

    .login-all {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      .login-right {
        font-size: 16px;
        padding: 10px 18px;
        cursor: pointer;
        font-weight: 500;
      }

      .button {
        font-size: 16px;
        font-weight: 500;
        padding: 10px 18px;
        border-radius: 4px;
        background-color: rgb(189, 219, 228);
        border: 1px solid #ffff;
        cursor: pointer;
      }

      .right-menu {
        display: flex;
        align-items: center;
        flex-direction: "row";
        justify-content: "space-between";
        width: 100%;
        flex: 1;

        .advisoryCont {
          padding: 8px 18px;
          border-radius: 4px;
          cursor: pointer;
          margin-right: 10px;
          background: lightblue;

          .advisoryText {
            color: black;
            font-size: 12px;
          }
        }
      }
    }

    .masked {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.75);
      z-index: 100;

      .masked-all {
        width: 100%;
        height: 367px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      .masked-word {
        display: flex;
        justify-content: space-between;
        font-size: 22px;
        margin: 8px;
        color: #ffffff;
      }

      .masked-login {
        background: #086d68;
        height: 400px;
        padding-left: 3px;

        .el-form-item {
          margin-top: 20px;
          margin-left: 15px;
          margin-bottom: 28px;
        }

        .masked-check {
          margin-left: 15px;
          color: #ffff;
          font-size: 12px;
          margin-top: 8px;
        }

        .send-login {
          display: flex;
          margin-left: 20px;
        }

        .google-icon {
          width: 20px;
          height: 20px;
        }

        .masked-foot {
          margin-left: 39px;
          margin-top: 8px;

          p {
            color: #ffff;
            font-size: 12px;
            margin-top: 8px;
          }

          span {
            color: #eaee03;
            cursor: pointer;
          }
        }
      }

      .masked-reginster {
        padding-top: 20px;
        background: #086d68;
        height: 410px;
        padding-left: 20px;

        .regester-foot {
          margin-top: 15px;
          // text-align: center;
          color: #ffffff;
          font-size: 12px;
          line-height: 18px;
          padding: 5px 47px 25px 30px;

          span {
            margin-left: 5px;
            color: #eaee03;
            cursor: pointer;
          }
        }
      }
    }

    .masked-new {
      background: rgba(0, 0, 0, 0.75);
      justify-content: center;
      align-items: flex-end;
      position: fixed;
      width: 100%;
      height: 100%;
      display: flex;
      inset: 0px;
      overflow: hidden;
      z-index: 1000;

      .masked-all-new {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        flex-direction: column;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        .masked-back-button {
          position: relative;
          top: 27px;
          left: 23px;
          z-index: 10;
          display: flex;
          align-self: flex-start;
          width: 90%;
          height: 5%;
          .masked-back-button-img {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
        }
        .masked-content {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          max-width: 220px;
          padding-top: 30px;
          padding-bottom: 45px;
          padding-left: 10px;
          padding-right: 10px;
          .masked-content-logo {
            max-width: 200px;
            max-height: 200px;
            width: 65px;
            height: 65px;
            object-fit: contain;
          }
          .masked-input {
            width: 100%;
            min-width: 220px;
            border: 1px solid #cecece;
            border-radius: 4px;
            background-color: #fff;
            text-align: center;
            justify-content: center;
            height: 44px;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 500;
          }
          .keep-login {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            font-size: 13px;
            .keep-login-word {
              margin-left: 8px;
            }
          }
          .login-and-join-button {
            outline: none;
            border: none;
            cursor: pointer;
            background: none;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 44px;
            border-radius: 4px;
            background-color: rgb(90, 181, 209);
            margin-top: 10px;
            font-size: 16px;
            font-weight: 900;
            color: #fff;
          }
          .forgot-password-button {
            border: none;
            padding: 0;
            cursor: pointer;
            justify-content: center;
            width: 100%;
            margin-top: 20px;
            font-size: 13px;
            font-weight: 700;
            color: rgb(90, 181, 209);
            background: #fff;
          }
          .connect-with-word_login {
            font-size: 12px;
            font-weight: 500;
            margin-top: 24px;
            color: #bfbfbf;
          }
          .connect-with-word_register {
            font-size: 12px;
            font-weight: 500;
            margin-top: 15px;
            color: #bfbfbf;
          }
          .google-facebook-buttons-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            min-width: 220px;
            align-items: center;
            justify-content: center;
            flex: 1;
            padding: 5px;
            .facebook-button {
              width: 220px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              cursor: pointer;
              align-items: center;
              padding: 7px 0;
              border-radius: 4px;
              font-size: 14px;
              color: #3c4043;
              background-color: #fff;
              border: 1px solid #dadce0;
              margin-bottom: 5px;
              img {
                padding-right: 7px;
              }
            }
            .google-button {
              width: 220px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              cursor: pointer;
              align-items: center;
              padding: 7px 0;
              border-radius: 4px;
              font-size: 14px;
              color: #3c4043;
              background-color: white;
              border: 1px solid #dadce0;
              flex: 1;
              img {
                padding-right: 7px;
              }
            }
          }
          .switch-page-word-link {
            border: none;
            padding: 0;
            cursor: pointer;
            justify-content: center;
            margin-top: 20px;
            font-size: 13px;
            font-weight: 400;
            color: rgb(90, 181, 209);
            background: #fff;
          }
          .register-description {
            display: block;
            text-align: center;
            margin-top: 10px;
            font-size: 10px;
            width: 100%;
            color: #a3a3a3;
            .faq_and_tc_word {
              color: rgb(90, 181, 209);
              font-weight: 500;
            }
          }
          .reset-password-word {
            text-align: center;
            margin-top: 1em;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-size: 15px;
            font-weight: bold;
          }
          .reset-password-word-description {
            text-align: center;
            margin-top: 1em;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-size: 15px;
            color: #a3a3a3;
          }
          .register-code-description {
            margin-top: 1em;
            text-align: center;
            font-size: 15px;
            color: #a3a3a3;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .login-form {
      position: relative;
      padding: 16px 16px 16px 27px;
      margin: 0 auto;
      overflow: hidden;

      :deep(.el-form-item) {
        height: 50px;
        color: #454545;
      }

      .login-input {
        :deep(.el-input__wrapper) {
          background-color: transparent;

          .el-input__inner {
            background-color: transparent;
            border: 1px solid #eaee03;
            color: #ffffff;
            // margin-left: 18px;
          }
        }
      }

      :deep(.el-input) {
        display: inline-block;
        height: 47px;
        width: calc(100% - 34px);

        .el-input__wrapper {
          padding: 0;
          border: none;
          width: 100%;
          box-shadow: none;
        }

        input {
          height: 47px;
          width: 100%;
          /* background: $inputBg !important; */
          opacity: 1;
          background-color: rgba(255, 255, 255, 0.247);
          padding: 12px 5px 12px 15px;
        }
      }
    }

    .pay-all {
      width: 600px;
      margin: auto;
      margin-top: 60px;

      .pay-word {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        margin: 8px 0;
        color: #ffffff;
      }
    }
  }

  :deep(.el-layout) {
    box-shadow: none;
    background-color: transparent;

    .el-dialog__body {
      padding: 0;
    }
  }

  .computer {
    display: none;
  }

  .size_class {
    z-index: 15;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .side_left {
    background-color: white;
    display: flex;
    padding: 10px;
    width: 60%;
    height: 100%;
    position: fixed;
    top: 0px;
    flex-direction: column;
    z-index: 1000;

    .closeBtn {
      width: 20px;
      height: 20px;
      object-fit: contain;
      align-self: flex-end;
      cursor: pointer;
    }

    .menuCont {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .left_button {
        color: black;
        text-align: left;
        padding: 5px 20px;
        cursor: pointer;
        font-weight: 700;
      }
    }
  }
}
</style>
<template>
  <div class="navbar">
    <div class="left-menu" @click.stop="goOnlineExpert">
      <div v-if="route.path !== '/callPlayer/receive'" class="zwIcon">
        <img
          v-if="route.path !== '/callPlayer/receive'"
          src="@/assets/burger-menu.png"
          style="width: 25px; height: 25px; color: white"
          @click.stop="openSide"
        />
      </div>
      <div class="zwLogo">
        <img src="@/assets/mainLogo.png" alt="" class="mainLogo" />
      </div>
    </div>

    <div @click="onUser" class="mobile">
      <el-popover
        placement="bottom"
        :width="200"
        :height="400"
        trigger="click"
        :visible="mobleVisible"
      >
        <div style="height: 210px">
          <div style="text-align: center">
            <p style="margin: 30px 0 10px 0">
              <span @click="onMobleSet"> Settings </span>
            </p>
            <p>
              <span @click="loginOut"> Sign out </span>
            </p>
          </div>
        </div>
      </el-popover>
    </div>

    <el-drawer
      size="93.1%"
      v-model="movieSet"
      title="Settings"
      direction="btt"
      :before-close="handleClose"
    >
      <div class="mobleSet">
        <h2>ACCOUNT</h2>
        <div class="mobleSet-mid">
          <div class="userMasg">
            <p class="p1">Email</p>
            <p class="p2">
              {{ mobleUserInfo.email }}
            </p>
          </div>

          <div class="userMasg" style="position: relative">
            <p class="p1">UserName</p>
            <p class="p2" @click="userFn">
              <span style="margin-right: 3px">
                {{
                  mobleUserInfo.nickname.length > 19
                    ? mobleUserInfo.nickname.substr(0, 18) + ".."
                    : mobleUserInfo.nickname
                }}
              </span>
              <span
                style="
                  padding-right: 10px;
                  vertical-align: -8px;
                  font-size: 25px;
                  color: rgb(128, 128, 53);
                "
              >
                <el-icon v-if="dialogUsername"><CaretBottom /></el-icon>
                <el-icon v-else><CaretRight /></el-icon>
              </span>
            </p>
          </div>

          <div class="userMasg">
            <p class="p1">Change Password</p>
            <p class="p2">
              <span
                @click="changePass"
                style="
                  padding-right: 10px;
                  vertical-align: -8px;
                  font-size: 25px;
                  color: rgb(128, 128, 53);
                "
                ><el-icon v-if="mobleIsShow"><CaretBottom /></el-icon>
                <el-icon v-else><CaretRight /></el-icon
              ></span>
            </p>
          </div>
          <!-- 

    <el-icon><CaretRight /></el-icon>,<el-icon><CaretBottom /></el-icon>
           -->
          <div class="userMasg">
            <p class="p1">Top Up Record</p>
            <p class="p2">
              <span
                @click="RechargeFn"
                style="
                  padding-right: 10px;
                  vertical-align: -8px;
                  font-size: 25px;
                  color: rgb(128, 128, 53);
                "
              >
                <el-icon v-if="isFechargeList"><CaretBottom /></el-icon>
                <el-icon v-else><CaretRight /></el-icon
              ></span>
            </p>
          </div>
          <div class="userMasg">
            <p class="p1" style="width: 50%">Consumption Record</p>
            <p class="p2">
              <span
                @click="ConsumptionFn"
                style="
                  padding-right: 10px;
                  vertical-align: -8px;
                  font-size: 25px;
                  color: rgb(128, 128, 53);
                "
                ><el-icon v-if="isConsumption"><CaretBottom /></el-icon>
                <el-icon v-else><CaretRight /></el-icon
              ></span>
            </p>
          </div>

          <div
            style="
              margin-top: 30px;
              padding: 25px 5px;
              border-radius: 6px;
              background-color: rgba(240, 239, 239, 0.776);
            "
            v-if="dialogUsername"
          >
            <div class="userMasg pActive">
              <p class="p1">New Username:</p>
              <p class="p2">
                <el-input v-model="newUsername" placeholder="New Username" />
              </p>
            </div>

            <div style="display: flex; justify-content: space-evenly; margin-top: 20px">
              <el-button round @click="newUsername = ''">Reset</el-button>
              <el-button
                type="primary"
                @click="changeUsername"
                round
                :loading="passLoading"
                :disabled="passDisable"
                >verify</el-button
              >
            </div>
          </div>
          <div
            style="
              margin-top: 30px;
              padding: 25px 5px;
              border-radius: 6px;
              background-color: rgba(240, 239, 239, 0.776);
            "
            v-if="mobleIsShow"
          >
            <div class="userMasg pActive">
              <p class="p1">Old password:</p>
              <p class="p2">
                <el-input
                  v-model="mobleForm.oldPwd"
                  type="password"
                  placeholder="Old password"
                  show-password
                />
              </p>
            </div>

            <div class="userMasg pActive">
              <p class="p1">New pasword:</p>
              <p class="p2">
                <el-input
                  v-model="mobleForm.newPwd"
                  type="password"
                  placeholder="New pasword"
                  show-password
                />
              </p>
            </div>

            <div class="userMasg pActive">
              <p class="p1">Confirm password:</p>
              <p class="p2">
                <el-input
                  v-model="mobleForm.checkPassword"
                  type="password"
                  placeholder="Confirm password"
                  show-password
                />
              </p>
            </div>

            <div style="display: flex; justify-content: space-evenly; margin-top: 20px">
              <el-button round @click="resetPass">Reset</el-button>
              <el-button
                type="primary"
                @click="onChangePassword"
                round
                :loading="passLoading"
                :disabled="passDisable"
                >verify</el-button
              >
            </div>
          </div>

          <div
            v-if="isFechargeList"
            class="rechargeList"
            ref="listRef"
            style="
              margin-top: 30px;
              padding: 15px 5px;
              border-radius: 6px;
              height: 300px;
              background-color: rgba(240, 239, 239, 0.776);
              overflow: auto;
            "
          >
            <div>
              <h4 style="text-align: center; margin-bottom: 10px">Top Up Record</h4>
            </div>

            <div>
              <div v-for="item in list" :key="item.id" class="list-box">
                <div style="font-size: 12px; width: 87%">{{ item.createtime }}</div>
                <div>{{ item.amount.toFixed(2) }}</div>
                <div :style="{ color: colorArr[item.status] }">
                  {{ statusArr[item.status] }}
                </div>
              </div>
            </div>
            <div
              v-if="!lazyFlag"
              style="text-align: center; font-size: 12px; color: #2f906d"
            >
              <span @click="showRecharge">Show More</span>
            </div>
            <div
              v-if="lazyFlag"
              style="text-align: center; font-size: 12px; color: #b6b6b6"
            >
              <span>No More</span>
            </div>
          </div>

          <div
            v-if="isConsumption"
            class="rechargeList"
            ref="listRef1"
            style="
              margin-top: 30px;
              padding: 15px 5px;
              border-radius: 6px;
              height: 300px;
              background-color: rgba(240, 239, 239, 0.776);
              overflow: auto;
            "
          >
            <div>
              <h4 style="text-align: center; margin-bottom: 10px">Consumption Record</h4>
            </div>

            <div>
              <div v-for="item in list1" :key="item.id" class="list-box">
                <div style="font-size: 12px; width: 100%">{{ item.createtime }}</div>
                <div>{{ item.changeCoin }}</div>
              </div>
            </div>
            <div
              v-if="!lazyFlag1"
              style="text-align: center; font-size: 12px; color: #2f906d"
            >
              <span @click="showConsumption">Show More</span>
            </div>
            <div
              v-if="lazyFlag1"
              style="text-align: center; font-size: 12px; color: #b6b6b6"
            >
              <span>No More</span>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <div class="mobile" v-if="sideShow">
      <!-- 移动端导航栏关闭 -->
      <img
        src="@/assets/close.png"
        style="width: 25px; height: 25px"
        @click.stop="openSide"
      />
    </div>

    <!-- v-if="da.consulantType == '1'" -->
    <div
      class="pc"
      @click="getCouponFn"
      style="cursor: pointer; font-size: 18px; margin-right: 20px;"
    >
      <img src="@/assets/free_coupon_banner.gif" style="width:450px;height:55px;object-fit: cover; border-radius: 12px">
    </div>

    <!-- <div v-else class="computer"> -->
    <div class="login-all" v-if="!getToken()">
      <!-- 登录 -->
      <div class="login-right" @click="openLogin">
        {{ $t("person.name") }}
      </div>
      <!-- 注册 -->
      <b-button class="button" @click="openRegister">{{ "Join" }}</b-button>
    </div>

    <div class="login-all" v-if="getToken() && getuserInfo">
      <div class="right-menu">
        <div
          class="advisoryCont"
          v-if="getType() == '1' && getToken()"
          @click="openProfileInfo()"
        >
          <div class="advisoryText">Advisory Center</div>
        </div>
        <el-popover
          placement="bottom-end"
          effect="dark"
          :width="200"
          trigger="click"
          :visible="isCenter"
        >
          <template #reference>
            <div
              class="login-right1"
              @click="isCenter = !isCenter"
              v-if="getType() !== '1' && getToken()"
            >
              <svg-icon class="left-icon" icon="user"></svg-icon>
              <span>{{
                getuserInfo.nickname.length > 7
                  ? getuserInfo.nickname.substr(0, 7) + ".."
                  : getuserInfo.nickname
              }}</span>
              <svg-icon class="right-icon" icon="xiala"></svg-icon>
            </div>
          </template>
          <el-row class="check-top">
            <el-col>
              <div style="margin-bottom: 20px" class="check-icon">Your Credits</div>
              <ul style="list-style: none" class="check-bottom">
                <li style="cursor: pointer; padding: 6px 0" @click="goLoveList">
                  <svg-icon icon="aixin"></svg-icon>
                  Favorites
                </li>
                <li style="cursor: pointer; padding: 6px 0" @click="goHistory">
                  <svg-icon icon="lishijilu"></svg-icon>
                  My History
                </li>
                <li
                  style="cursor: pointer; padding: 6px 0"
                  @click="changeMessageLayout(true)"
                >
                  <svg-icon icon="xiaoxi"></svg-icon>
                  Messenger
                </li>
                <!-- isCenter.value =false  da.isPayShow = true-->
                <li style="cursor: pointer; padding: 6px 0" @click="getCredO">
                  <svg-icon icon="role"></svg-icon>
                  Credits
                </li>
                <li style="cursor: pointer; padding: 6px 0" @click="goSetting">
                  <svg-icon icon="shezhi"></svg-icon>
                  Account detail
                </li>
                <li
                  v-loading.fullscreen.lock="fullscreenLoading"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                  style="cursor: pointer; padding: 6px 0"
                  @click="loginOut"
                >
                  <svg-icon icon="tuichu"></svg-icon>
                  Logout
                </li>
              </ul>
            </el-col>
          </el-row>
        </el-popover>
      </div>
    </div>
    <!-- </div> -->
    <!-- 语言 -->
    <!-- 遮罩层登录 -->
    <div class="masked-new" v-show="da.isLoginShow">
      <div class="masked-all-new" v-show="(da.type = '2')">
        <div class="masked-back-button">
          <img
            class="masked-back-button-img"
            src="@/assets/left-arrow.png"
            @click="loginBackFunction"
          />
        </div>
        <div class="masked-content">
          <img class="masked-content-logo" src="@/assets/mainLogoOnly.png" alt="" />
          <el-form ref="loginFromRef" :model="loginForm" :rules="loginRules">
            <input
              prop="email"
              placeholder="Email"
              class="masked-input"
              type="email"
              v-model="loginForm.email"
            />
            <span v-if="error.login.email" style="color: #e05959; font-weight: 500">{{
              error.login.email
            }}</span>
            <input
              prop="password"
              placeholder="Password"
              class="masked-input"
              type="password"
              v-model="loginForm.password"
              show-password
            />
            <span v-if="error.login.password" style="color: #e05959; font-weight: 500">{{
              error.login.password
            }}</span>
            <div class="keep-login">
              <input type="checkbox" v-model="da.checked1" />
              <span class="keep-login-word">Keep me logged in</span>
            </div>
            <button
              class="login-and-join-button"
              type="button"
              v-loading.fullscreen.lock="fullloginLoading"
              :disabled="getLoginDisable"
              :loading="loginLoading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              @click="loginFn"
            >
              Login
            </button>
          </el-form>
          <button
            class="forgot-password-button"
            type="button"
            @click="
              () => {
                da.isLoginShow = false;
                da.isForgotPass = true;
              }
            "
          >
            I forgot my password
          </button>
          <span class="connect-with-word_login">CONNECT WITH</span>
          <div class="google-facebook-buttons-container">
            <button class="facebook-button" type="button" @click="facebookLogin">
              <img style="width: 21px; object-fit: contain" src="@/assets/fb_login.svg" />
              <p>Facebook</p>
            </button>
            <GoogleLogin
              :callback="handleCredentialResponse"
              class="google-button"
              popup-type="TOKEN"
            >
              <img
                style="width: 21px; object-fit: contain"
                src="@/assets/google_login.png"
              />
              <p>Google</p>
            </GoogleLogin>
          </div>
          <button class="switch-page-word-link" type="button" @click="goReg">
            <b>Don’t have an account?</b>
          </button>
        </div>
      </div>
    </div>
    <!-- 找回密码 -->
    <div class="masked-new" v-show="da.isForgotPass">
      <div class="masked-all-new" v-show="(da.type = '2')">
        <div class="masked-back-button">
          <img
            class="masked-back-button-img"
            src="@/assets/left-arrow.png"
            @click="forgotPassBackFunction"
          />
        </div>
        <div class="masked-content">
          <img class="masked-content-logo" src="@/assets/mainLogoOnly.png" alt="" />
          <span class="reset-password-word"><b>Reset your Zest Wisdom Password</b></span>
          <span class="reset-password-word-description" v-if="isEmail"
            >Submit your email address and we'll send you an password reset
            instructions.</span
          >
          <span class="reset-password-word-description" v-if="isCode"
            >An email with password reset instructions has been sent to your email
            address, if it exists on our system.</span
          >
          <el-form
            ref="forgotPassFromRef"
            :model="ForgotForm"
            :rules="loginRules"
            v-if="isEmail"
          >
            <input
              prop="email"
              placeholder="Email"
              class="masked-input"
              type="email"
              v-model="ForgotForm.email"
            />
            <span v-if="error.forgot.email" style="color: #e05959; font-weight: 500">{{
              error.forgot.email
            }}</span>
            <button
              class="login-and-join-button"
              type="button"
              :disabled="sendText == 'SEND' ? false : true"
              @click="sendEmail"
            >
              {{ sendText }}
            </button>
          </el-form>
          <el-form
            ref="forgotPassFromRef"
            :model="ForgotForm"
            :rules="loginRules"
            v-if="isCode"
          >
            <input
              prop="code"
              placeholder="Code"
              class="masked-input"
              v-model="ForgotForm.code"
            />
            <button class="login-and-join-button" type="button" @click="nextOne">
              NEXT
            </button>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 注册 -->
    <div class="masked-new" v-show="da.isRegisterShow">
      <div class="masked-all-new">
        <div class="masked-back-button">
          <img
            class="masked-back-button-img"
            src="@/assets/left-arrow.png"
            @click="registerBackFunction"
          />
        </div>
        <div class="masked-content">
          <img class="masked-content-logo" src="@/assets/mainLogoOnly.png" alt="" />
          <span class="connect-with-word_register">CONNECT WITH</span>
          <div class="google-facebook-buttons-container">
            <button class="facebook-button" type="button" @click="facebookLogin">
              <img style="width: 21px; object-fit: contain" src="@/assets/fb_login.svg" />
              <p>Facebook</p>
            </button>
            <GoogleLogin
              :callback="handleCredentialResponse"
              class="google-button"
              popup-type="TOKEN"
            >
              <img
                style="width: 21px; object-fit: contain"
                src="@/assets/google_login.png"
              />
              <p>Google</p>
            </GoogleLogin>
          </div>
          <span class="connect-with-word_login">OR CREATE ACCOUNT</span>
          <el-form ref="registerFromRef" :model="RegisterForm" :rules="loginRules">
            <input
              prop="email"
              placeholder="Email"
              class="masked-input"
              type="email"
              v-model="RegisterForm.email"
              required
            />
            <span v-if="error.register.email" style="color: #e05959; font-weight: 500">{{
              error.register.email
            }}</span>
            <input
              prop="password"
              placeholder="Password"
              class="masked-input"
              type="password"
              v-model="RegisterForm.password"
              required
            />
            <span
              v-if="error.register.password"
              style="color: #e05959; font-weight: 500"
              >{{ error.register.password }}</span
            >
            <el-button
              class="login-and-join-button"
              type="button"
              @click="debounceRegisterFn"
              :loading="createLoading"
              :disabled="getRegDisable"
            >
              Join Now for FREE
            </el-button>
          </el-form>
          <div class="register-description">
            By clicking on "Join Now for FREE", you agree to our
            <a class="faq_and_tc_word" @click="router.push('/pageTerm')"
              >Terms & Conditions</a
            >
            and
            <a class="faq_and_tc_word" @click="router.push('/pageQuestion')">F.A.Q</a>
            and that, due to the nature of this site, sensitive data reflecting your
            beliefs may be processed.
          </div>
          <button class="switch-page-word-link" type="button" @click="goLogin">
            <b>Already have an account?</b>
          </button>
        </div>
      </div>
    </div>
    <!-- 用户注册code板块 -->
    <div class="masked-new" v-if="da.isRegisterShowCode">
      <div class="masked-all-new">
        <div class="masked-back-button">
          <img
            class="masked-back-button-img"
            src="@/assets/left-arrow.png"
            @click="registerCodeBackFunction"
          />
        </div>
        <div class="masked-content">
          <img class="masked-content-logo" src="@/assets/mainLogoOnly.png" alt="" />
          <div class="register-code-description">
            A validation code has been sent to your email address:
            <p style="color: rgb(90, 181, 209)">{{ RegisterForm.email }}</p>
            <br />
            Please fill up the text box below with the code and click 'Confirm'. If you
            didn't receive the email, please resend the validation code in
            <span @click="debounceNextYz" style="color: #ffa500; cursor: pointer"
              >Resend</span
            >
          </div>
          <el-form
            ref="registerFromRefCode"
            :model="RegisterFormCode"
            :rules="loginRules"
          >
            <input
              prop="code"
              placeholder="Code"
              class="masked-input"
              v-model="RegisterFormCode.code"
            />
            <span v-if="error.register.code" style="color: #e05959; font-weight: 500">{{
              error.register.code
            }}</span>
            <button
              class="login-and-join-button"
              :loading="FinishFning"
              :disabled="getCodeBtnDisable"
              type="button"
              @click="debounceFinishFn"
            >
              Confirm
            </button>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 购买点数 -->
    <div class="masked" v-if="da.isPayShow">
      <div class="pay-all animate__animated animate__fadeInUp">
        <div class="pay-word">
          <div>Quick Buy</div>
          <div style="font-size: 12px">
            <span style="margin-right: 5px; color: aliceblue">{{ $t("YouHave") }}:</span>
            <strong style="margin-right: 5px; color: yellow"
              >{{ userStore.balance }} {{ $t("Credits") }}</strong
            >
            <svg-icon
              style="cursor: pointer; font-size: 20px"
              icon="chahao"
              @click="closPay()"
            ></svg-icon>
            <!-- <button @click="page1StoreDa.userInfo.coinBalance = '123456'">点击</button> -->
          </div>
        </div>
        <!-- <div>
          <button @click="onText">test</button>
        </div> -->
        <div class="pay-content">
          <div class="pay-list pc">
            <div
              v-for="(item, index) in da.payList"
              :key="index"
              class="checkIndex"
              :class="{ selected: da.selected === index }"
              @click="checkCredits(item, index, item.id)"
            >
              <div class="pay-shu">{{ item.coinAmount }}</div>
              <p class="pay-words">Credits</p>
              <p class="pay-buy" @click="paypalFn(item, index, item.id)">
                {{ $t("buyCredits") }}
              </p>
              <p class="pay-money">${{ item.price }}</p>
            </div>
          </div>
          <!-- <div class="pay-typle">
            <p>Please confirm the points you need to purchase</p>
            <div class="pay-yue" @click="paypalFn">
              <img class="paypal-icon" src="@/assets/Paypal.png" alt="" />
            </div>
          </div> -->
          <ul class="mobile pay-list1">
            <li
              v-for="(item, index) in da.payList"
              :key="index"
              class="checkIndex"
              :class="{ selected: da.selected === index }"
              @click="checkCredits(item, index, item.id)"
            >
              <div class="pay-shu">{{ item.coinAmount }} Credits</div>
              <p class="pay-money">${{ item.price }}</p>
              <p class="pay-buy" @click="paypalFn(item, index, item.id)">
                {{ $t("buyCredits") }}
              </p>
            </li>
          </ul>
          <div class="pay-footer">
            <p>Have coupon? Check out our <a style="cursor: pointer; color: chartreuse" @click="goPageQuestion">FAQ</a> on how to use it.</p>
            <p>{{ $t("payFootOne") }}</p>
            <!-- <p>
              {{ $t('payFootTwo') }}
              <span style="color: chartreuse">{{ $t('payFootTwoType') }}</span>
            </p> -->
            <p>
              {{ $t("needHelp")
              }}<span style="color: chartreuse">
                <a href="mailto:support@zestwisdom.com">{{ ' ' + $t("KEFU") }}</a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 优惠券领取之后的话弹窗提示 -->
    <el-dialog
      v-model="afterIsCoupon"
      title="Coupon"
      width="30%"
      @close="afterIsCoupon = false"
    >
      <h3>This coupon has been claimed.</h3>
    </el-dialog>
    <!-- 信箱 -->
    <messageLayout
      :showModelVal="showModelVal"
      v-show="showModelVal"
      @showModelValChange="changeMessageLayout"
    />
    <phoneMessage></phoneMessage>
  </div>
  <div class="size_class" v-if="sideShow">
    <div class="side_left">
      <img src="@/assets/close_grey.png" alt="" class="closeBtn" @click.stop="openSide" />
      <div class="menuCont">
        <sidebar id="guide-sidebar" @onMenuItemClicked="openSide" />
        <div class="menuCont_bottom">
          <div class="left_button" v-if="!getType() && !getToken()" @click="goPageLogin">
            Experts Login
          </div>
          <div class="left_button" v-if="!getType() && !getToken()" @click="goPageReg">
            Experts Register
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  ArrowRightBold,
  ArrowDownBold,
  ArrowRight,
  CaretRight,
  CaretBottom,
} from "@element-plus/icons-vue";
import phoneMessage from "./phoneMessage.vue";
import { phoneStore } from "../../store/phone1";
import { recordsAPI } from "@/api/center.js";
import {
  computed,
  reactive,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
  defineProps,
  watchEffect,
  nextTick,
} from "vue";
import messageLayout from "./messageLayout.vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import horizontal from "./Sidebar/horizontal.vue";
import { page1Store } from "@/store/page1";
import { page3Store } from "@/store/page3";
import {
  register,
  login,
  updatePassWord,
  faceBookLogin,
  payCredits,
  forgotPassapi,
  forgotCodeapi,
  updateAvatar,
} from "@/api/sys.js";
import { ElMessage, ElLoading, ElMessageBox } from "element-plus";
import {
  setToken,
  getToken,
  removeToken,
  getType,
  setType,
  removeType,
  getConlant,
} from "@/utils/auth";
import { useStore } from "vuex";
import request from "@/utils/request.js";
import bus from "vue3-eventbus";
import { debounce } from "lodash";
import vueConfig from "../../../vue.config";
import Sidebar from "../components/Sidebar/index.vue";
import axios from "axios";
import iNotify from "@/utils/inotify.js";
const imgUrl = process.env.VUE_APP_BASE_ImageUrl;
const isCenter = ref(false);
let externalLogin = reactive({
  appId: "",
  nickname: "",
  email: "",
  avatar: "",
  type: "",
});
const dialogUsername = ref(false);
const userInfoData = ref({});
const newUsername = ref("");
//用户头像
const userImg = ref();
//用户id
const userId = ref();
const afterIsCoupon = ref(false); //已经领取之后的话 弹窗提示
const mobleUserInfo = ref({});
const passLoading = ref(false);
const passDisable = ref(false);
const mobleIsShow = ref(false);
const mobleForm = reactive({
  oldPwd: "",
  newPwd: "",
  checkPassword: "",
});
const isFechargeList = ref(false);
const isConsumption = ref(false);
const mobleVisible = ref(false);
const movieSet = ref(false);
const msgVal = ref(111);
const aixinFlag = ref(false);
const sideShow = ref(false);
const userStore = phoneStore();
const codeBtnWord = ref("Join Now for FREE"); // 获取验证码按钮文字
const waitTime = ref(60); // 获取验证码按钮失效时间
const getCodeBtnDisable = ref(false); //注册获取验证码
const getLoginDisable = ref(false); //登录按钮
const loginLoading = ref(false); //登录验证码
const getRegDisable = ref(false); //注册按钮
const regLoading = ref(false); //注册验证码
const faceBookId = ref("");
const store = useStore();
const router = useRouter();
const route = useRoute();
const da = reactive({
  languageArr: [
    {
      name: "Chinese",
      value: "zh",
    },
    {
      name: "English",
      value: "en",
    },
  ],
  visible: false, //控制语言框
  isLoginShow: "", //控制登录面
  isForgotPass: "", //控制忘记密码页面
  isRegisterShow: false, //控制注册面
  isRegisterShowCode: false, //控制注册code面
  isPayShow: false, //控制购买点数
  isChatting: false, //控制聊天室页面
  type: "1",
  checked1: true, //登录页面保持登录勾选
  token: "",
  consulantType: "", //区别顾问登录和会员登录
  noConType: "",
  name: {},
  seach: "", ///信箱里面的搜索
  payList: [],
  selected: "",
  checkList: "", //选择的点数信息
});
const statusArr = ref(["To be paid", "Have paid", "Cancelled", "Payment failure"]);
const colorArr = ref(["#807d39", "green", "red", "red"]);
const query = reactive({
  type: "1",
  pageSize: 10,
  pageNum: 1,
});
const listRef = ref(null);
const lazyFlag = ref(false);
const list = ref([]);

// =====
const query1 = reactive({
  type: "2",
  pageSize: 10,
  pageNum: 1,
});
const listRef1 = ref(null);
const lazyFlag1 = ref(false);
const list1 = ref([]);

const { locale } = useI18n();
const { $t } = getCurrentInstance().proxy;
console.log($t("person.name")); // => 获取值
const page1StoreDa = page1Store();
const page3StoreDa = page3Store();
const payid = ref(null);
const props = defineProps({
  payStatus: Boolean,
});

const RechargeFn = () => {
  console.log("RechargeFn");
  isFechargeList.value = !isFechargeList.value;
  dialogUsername.value = false;
  mobleIsShow.value = false;
  isConsumption.value = false;
};

const ConsumptionFn = () => {
  isConsumption.value = !isConsumption.value;
  dialogUsername.value = false;
  mobleIsShow.value = false;
  isFechargeList.value = false;
};

const onText = async () => {
  await request.get(`/system/paypal/pays?id=${userId.value}`);
};

const getRechargeList = async (obj) => {
  let { rows, code, total } = await recordsAPI(obj);
  if (code == 200) {
    if (query.pageNum > total / 10) {
      list.value = list.value.concat(rows);
      lazyFlag.value = true;
      console.log("已经到底了");
      return;
    } else {
      list.value = list.value.concat(rows);
    }
  }
  console.log(rows, "rows");
};

const getConsumptionList = async (obj) => {
  let { rows, code, total } = await recordsAPI(obj);
  if (code == 200) {
    if (query1.pageNum > total / 10) {
      list1.value = list1.value.concat(rows);
      lazyFlag1.value = true;
      console.log("已经到底了");
      return;
    } else {
      list1.value = list1.value.concat(rows);
    }
  }
  console.log(rows, "rows");
};

const userFn = () => {
  dialogUsername.value = !dialogUsername.value;
  mobleIsShow.value = false;
  isFechargeList.value = false;
  isConsumption.value = false;
};

const showRecharge = () => {
  if (lazyFlag.value == false) {
    query.pageNum += 1;
    getRechargeList(query);
  }
};

const showConsumption = () => {
  if (lazyFlag1.value == false) {
    query1.pageNum += 1;
    getConsumptionList(query1);
  }
};

function msgScroll1() {
  if (
    listRef.value.scrollTop + listRef.value.clientHeight > listRef.value.scrollHeight ||
    listRef.value.scrollTop + listRef.value.clientHeight == listRef.value.scrollHeight
  ) {
    if (lazyFlag.value == false) {
      query.pageNum += 1;
      getRechargeList(query);
    } else {
      console.log("到底dddddd");
    }
  }
}

//优惠券
const getCoupon = async () => {
  if (getType() == "1" || !getToken()) {
    da.isLoginShow = true;
    page1StoreDa.isLoginShow = true;
    return;
  }

  console.log("优惠券");
  let data = {
    userId: userId.value, //用户id
    discountId: 2, //优惠券id
  };
  const res = await request.post("/system/faUserDiscount/getCoupon", data);
  console.log(res, "data");
  if (res.code == "200" && res.data == "1") {
    // da.isCouponShow = true
    // couponList.value = data
    ElMessage({
      message: res.msg,
      type: "success",
    });
    return;
    // da.isCouponShow = true
  }

  if (res.code == "200" && res.data == "0") {
    // da.isCouponShow = true
    // couponList.value = data
    afterIsCoupon.value = true;
    return;
    // da.isCouponShow = true
  }
};

const getCredO = () => {
  isCenter.value = false;
  da.isPayShow = true;
};

const getCouponFn = debounce(getCoupon, 500); // 500表示延迟时间，您可以根据需要进行调整

//清空手机端修改密码输入框
const resetMobleInput = () => {
  mobleForm.oldPwd = "";
  mobleForm.newPwd = "";
  mobleForm.checkPassword = "";
};

const resetPass = () => {
  resetMobleInput();
};

//手机端修改密码
const onChangePassword = async () => {
  if (
    mobleForm.oldPwd !== "" &&
    mobleForm.newPwd !== "" &&
    mobleForm.checkPassword !== ""
  ) {
    console.log("进入");
    if (mobleForm.newPwd == mobleForm.checkPassword && mobleForm.newPwd.length > 5) {
      if (mobleForm.oldPwd == mobleForm.newPwd) {
        ElMessage({
          message: "The old and new passwords are the same.",
          type: "warning",
        });
        return;
      }
      passLoading.value = true;
      passDisable.value = true;

      let id = JSON.parse(localStorage.getItem("userInfo")).id;
      console.log(id, "id");
      const params = { id, oldPwd: mobleForm.oldPwd, newPwd: mobleForm.newPwd };
      let res = await updatePassWord(params);
      if (res.code == 200) {
        ElMessage({
          message: "Password changed successfully",
          type: "success",
        });
        passLoading.value = false;
        passDisable.value = false;
        resetMobleInput();
      } else {
        ElMessage({
          message: res.msg,
          type: "error",
        });
        passLoading.value = false;
        passDisable.value = false;
        resetMobleInput();
      }

      console.log(res, "res");
    } else {
      ElMessage({
        message:
          "The password must contain at least six characters or two passwords do not match each other",
        type: "warning",
      });
    }
  } else {
    ElMessage({
      message: "The old and new passwords cannot be empty",
      type: "warning",
    });
  }
};

//手机端展示修改密码
const changePass = () => {
  mobleIsShow.value = !mobleIsShow.value;
  dialogUsername.value = false;
  isFechargeList.value = false;
  isConsumption.value = false;
};

//手机端设置
const onMobleSet = () => {
  movieSet.value = true;
  mobleVisible.value = false;
};

//手机端右上角头像
const onUser = () => {
  console.log("头像");
  requestUserInfo();
  mobleVisible.value = !mobleVisible.value;
};

const gugeisShow = ref(false); //谷歌按钮的显隐
//打开侧边栏 移动端
const openSide = () => {
  userStore.serachShow = false;
  console.log("openSide");
  sideShow.value = !sideShow.value;
};
//打开搜索框
const serachOnline = () => {
  userStore.serachShow = !userStore.serachShow;
};

const goPageReg = () => {
  if (getToken() && getType()) {
    ElMessage({
      message: "Your account is logged in.",
      type: "warning",
    });
  } else {
    router.push("/pageReg");
  }
};

const goPageLogin = () => {
  if (getToken() && getType()) {
    ElMessage({
      message: "Your account is logged in.",
      type: "warning",
    });
  } else {
    router.push("/login");
  }
};
function handleGoogleLoginSuccess(response) {
  // 登录成功的处理逻辑
  console.log("Google login success:", response);
  da.isLoginShow = false;
  da.isRegisterShow = false;
}

const emit = defineEmits(["closPay"]);
//登录
const loginForm = ref({
  email: "",
  password: "",
});
//忘记密码
const ForgotForm = ref({
  email: "",
  code: "",
});
//注册
const RegisterForm = ref({
  password: "",
  email: "",
  code: "",
});

//注册code板块
const RegisterFormCode = ref({
  email: "",
});
const propNickName = ref(false);
const showModelVal = ref(false);
const fullscreenLoading = ref(false);
const fullloginLoading = ref(false);
const createLoading = ref(false); //注册验证码

const error = reactive({
  register: {
    email: false,
    password: false,
    code: false,
  },
  login: {
    email: false,
    password: false,
    code: false,
  },
  forgot: {
    email: false,
    password: false,
    code: false,
  },
});

// 点击打开聊天通讯弹窗（信箱）
function changeMessageLayout(e) {
  if (window.innerWidth > 768) {
    showModelVal.value = e;
    isCenter.value = false;
    window.localStorage.removeItem("gwId");
    return;
  } else {
    router.push("/mobleMsg");
    return;
  }
}
// 获取用户信息
const userInfo = ref(JSON.parse(store.state.userInfo));
const getuserInfo = computed(() => {
  return JSON.parse(store.state.userInfo);
});
watch(getuserInfo, (n, o) => {
  userInfo.value = n;
  // console.log('n,o', n, o)
});
//邮箱验证
const checkEmail = (rule, value, callback) => {
  const mailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!value) {
    error.email = "Email cannot be empty";
    callback(new Error("Email cannot be empty"));
    return;
  }
  setTimeout(() => {
    if (mailReg.test(value)) {
      callback();
    } else {
      error.email = "Please enter the correct email format";
      console.log(error.email, "ea");
      callback(new Error("Please enter the correct email format"));
    }
  }, 100);
};

const goPageQuestion = () => {
  router.push("/pageQuestion");
};
const goPageTrem = () => {
  router.push("/pageTerm");
};
//密码验证
const validatePass = (rule, value, callback) => {
  const pwdReg = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/;
  if (!value) {
    error.password = "Please enter the password";
    return callback(new Error("Please enter the password"));
  } else if (pwdReg.test(value)) {
    callback();
  } else {
    error.password = "Please enter your password no less than 6 characters.";
    callback(new Error("Please enter your password no less than 6 characters."));
  }
};

const loginRules = ref({
  email: [{ required: true, validator: checkEmail, trigger: "blur" }],
  password: [{ required: true, validator: validatePass, trigger: "blur" }],
  code: [{ required: true, trigger: "blur" }],
});

const activeLanguageName = computed(() => {
  return da.languageArr.find((item) => item.value === locale.value).name;
});

const isEmail = ref(true);
const isCode = ref(false);

const onMsg = () => {
  console.log("2222");
  msgVal.value = "";
  goNextMessg();
};
watchEffect(() => {
  da.isPayShow = props.payStatus;
});

onMounted(() => {
  if (getType() !== "1" && getToken()) {
    requestUserInfo();
  }
  getCreditsList();
  bus.on("LoginShow", (e) => {
    console.log(e, "bus");
    if (e) {
      console.log(e, "bus");
      da.isLoginShow = true;
    } else {
    }
  });
  bus.on("getUserId", () => userId.value);
  bus.on("getUser", () => {
    requestUserInfo();
  });
  bus.on("joinLogin", (e) => {
    if (e) {
      da.isLoginShow = true;
    }
  });
  bus.on("joinOnLogin", (e) => {
    if (e) {
      da.isLoginShow = true;
    }
  });
  bus.on("chatting", (e) => {
    console.log(window.innerWidth);
    if (window.innerWidth < 769) {
      router.push("/mobleMsg");
      return;
    }
    if (getType() == "1") {
      da.isLoginShow = true;
      return;
    }
    console.log(e, "bus");
    if (e) {
      showModelVal.value = e;
    }
  });
  bus.on("openBuyCredits", (e) => {
    da.isPayShow = e;
  });
  bus.on("paySuccess", (e) => {
    requestUserInfo();
    ElMessage({ type: "success", message: "success" });
  });
  if (getToken()) {
    da.token = getToken();
  }
  if (getType()) {
    da.consulantType = getType();
  }
  if (getConlant()) {
    da.noConType = getConlant();
  }

  if (getType() !== "1" && getToken()) {
    getRechargeList(query);
    getConsumptionList(query1);
    console.log(listRef.value, "listRef.value");
  }

  // if (window.innerWidth < 769) {
  //   listRef.value.addEventListener("scroll", function () {
  //     console.log("1111");
  //     msgScroll1();
  //   });
  // }
});
const changeVisible = () => {
  da.visible = true;
};
const handleClickLanguage = (val) => {
  locale.value = val;
  da.visible = false;
  localStorage.setItem("language", val);
  document.title = $t("title");
};
//点击登录
const openLogin = async () => {
  da.isLoginShow = true;
  page1StoreDa.isLoginShow = true;
};

//点击去顾问中心
const openProfileInfo = () => {
  if (route.path == "/callPlayer/receive") {
    ElMessageBox.alert("Hang up the phone before you leave this page!", "Warm and cozy", {
      confirmButtonText: "OK",
    });
    return;
  }
  router.push("/profileInfo");
};
//点击注册
const openRegister = async () => {
  page1StoreDa.isRegisterShow = true;
  da.isRegisterShow = true;
};
//去首页
const goOnlineExpert = () => {
  if (route.path == "/callPlayer/receive") {
    ElMessageBox.alert("Hang up the phone before you leave this page!", "Warm and cozy", {
      confirmButtonText: "OK",
    });
    return;
  }
  if (route.path == "/onlineExpert") {
    router.go(0);
  } else {
    router.push("/onlineExpert");
  }
};

// watch(route.path, (newVal, oldVal) => {
//   console.log(newVal,'newVal---------')
//   if (newVal == '/favorite') {
//     aixinFlag.value = true
//   } else {
//     aixinFlag.value = false
//   }
// })

//点击收藏图标
const goNextLove = () => {
  aixinFlag.value = true;
  if (getToken().length > 0) {
    router.push("/favorite");
    console.log("2211132312sdsdUUUUUUUUUUUUUUU");
    nextTick(() => {
      if (route.path == "/favorite") {
      } else {
        setTimeout(() => {
          aixinFlag.value = false;
        }, 0);
      }
    });
  } else {
    da.isLoginShow = true;
  }
};

//点击消息图标
const goNextMessg = () => {
  if (getToken()) {
    showModelVal.value = true;
  } else {
    da.isLoginShow = true;
    page1StoreDa.isLoginShow = true;
  }
};

// 获取商品积分列表
async function getCreditsList() {
  const { code, rows } = await request.get("/system/FaCoinpackage/list");
  if (code == 200) {
    da.payList = rows;
  }
  // console.log(rows, '获取积分列表')
}

// 购买点数
const goPayCredit = () => {
  if (!getToken()) {
    refreshGoogle();
    da.isLoginShow = true;
    return;
  }
  if (da.token) {
    da.isPayShow = true;
  } else {
    page1StoreDa.isLoginShow = true;
  }
};

const isSend = ref(false);
const emailTimer = ref(59);
const sendText = ref("SEND");

const changeUsername = () => {
  if (!newUsername.value) return;
  let obj = {
    ...userInfoData.value,
    nickname: newUsername.value,
  };
  updateAvatar(obj).then((res) => {
    if (res.code == 200) {
      requestUserInfo();
      ElMessage({
        message: "The user nickname is successfully modified",
        type: "success",
      });
      dialogUsername.value = false;
    } else {
      ElMessage({
        message: res.msg,
        type: "warning",
      });
    }
  });
};

//忘记密码
const forgotPassFromRef = ref(null);

const forgotPassBackFunction = () => {
  if (!isEmail.value) {
    isCode.value = false;
    isEmail.value = true;
  } else {
    da.isForgotPass = false;
    error.forgot.email = false;
  }
};

const loginBackFunction = () => {
  da.isLoginShow = false;
  error.login.email = false;
  error.login.password = false;
};

const registerBackFunction = () => {
  da.isRegisterShow = false;
  error.register.email = false;
  error.register.password = false;
};

const registerCodeBackFunction = () => {
  da.isRegisterShowCode = false;
  da.isRegisterShow = true;
  RegisterFormCode.value.code = "";
  error.register.code = false;
};

const sendEmail = async () => {
  let { email } = ForgotForm.value;
  const mailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  console.log(email, "forgot");
  if (!email) {
    error.forgot.email = "Email cannot be empty";
    return;
  } else if (!mailReg.test(email)) {
    console.log(mailReg.test(email), "result");
    error.forgot.email = "Please enter a valid email address";
    return;
  } else {
    error.forgot.email = false;
  }

  if (ForgotForm.value.email) {
    let res = await forgotPassapi({ email: ForgotForm.value.email });
    if (res.data == "Email send") {
      ElMessage({
        showClose: true,
        message: "Email sent successfully",
        type: "success",
      });
      isCode.value = true;
      isEmail.value = false;
    }
    if (res.data == "Email not registered") {
      ElMessage({
        showClose: true,
        message: "The email address is incorrectly entered or formatted",
        type: "error",
      });
      let timer = setInterval(() => {
        if (emailTimer.value > 1) {
          emailTimer.value--;
          sendText.value = `${emailTimer.value}s send`;
        } else {
          clearTimeout(timer);
          emailTimer.value = 60;
          sendText.value = "SEND";
        }
      }, 1000);
    }

    console.log(res, "res");
    console.log(ForgotForm.value.email);
  } else {
    ElMessage({
      showClose: true,
      message: "The mailbox cannot be empty.",
      type: "warning",
    });
  }
};

//忘记密码
const nextOne = async () => {
  let res = await forgotCodeapi({
    email: ForgotForm.value.email,
    code: ForgotForm.value.code,
  });
  console.log(res);
  if (res.data == "Email send") {
    ElMessage({
      showClose: true,
      message: "Successful verification",
      type: "success",
    });
    da.isForgotPass = false;
    da.isLoginShow = true;
    isCode.value = false;
    isEmail.value = true;
    ForgotForm.value.email = "";
    ForgotForm.value.code = "";
  } else {
    ElMessage({
      showClose: true,
      message: "Verification code error",
      type: "error",
    });
  }

  // forgotPassFromRef.value.validate((valid) => {
  //   console.log(valid,'忘记密码第一步')
  //   if (valid) {

  //   } else {
  //     ElMessage.error({
  //       grouping: true,
  //       message: 'The format of the email or verification code is incorrect',
  //       center: false
  //     })
  //     return false
  //   }
  // })
};
//忘记密码第二部

//会员登录
const loginFromRef = ref(null);
const loginFn = () => {
  console.log(loginForm.value, "oooo");
  loginFromRef.value.validate(async (valid) => {
    if (valid) {
      loginLoading.value = true;
      getLoginDisable.value = true;
      const { email, password } = loginForm.value;
      const mailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!email) {
        error.login.email = "Email cannot be empty";
        return;
      } else if (!mailReg.test(email)) {
        console.log(mailReg.test(email), "result");
        error.login.email = "Please enter a valid email address";
        return;
      } else {
        error.login.email = false;
      }

      const pwdReg = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/;
      if (!password) {
        error.login.password = "Please enter the password";
        return;
      } else {
        error.login.password = false;
      }
      const res = await login(loginForm.value);
      console.log(res, "登录");
      if (res.code == 200) {
        setType("");
        loginLoading.value = false;
        getLoginDisable.value = false;
        setToken(res.data.access_token);
        da.token = getToken();
        window.localStorage.setItem("token", res.data.access_token);
        da.isLoginShow = false;
        // page1StoreDa.isLoginShow = false
        page1StoreDa.username = loginForm.value.loginEmail;
        loginForm.value.loginEmail = "";
        loginForm.value.password = "";
        fullloginLoading.value = true;
        let result = await request.get("/system/faSettings/notification");
        if (result.code == "200" && result.data !== null) {
          for (let i = 0; i < result.data.length; i++) {
            console.log(result.data[i], "i");
            iNotify({
              title: "Private Session",
              message: result.data[i].name + result.data[i].hine,
              icon: imgUrl + result.data[i].images,
              options: "phone",
            });
          }
        }
        console.log(result, "result");
        // return;
        // location.reload()
        router.go(0);
        window.localStorage.setItem("registerActive", 1);
        setTimeout(() => {
          fullloginLoading.value = false;
          ElMessage.success({
            grouping: true,
            message: "Login succeeded",
          });
        }, 1000);
        requestUserInfo();
        location.reload();
      } else {
        loginLoading.value = false;
        getLoginDisable.value = false;
        ElMessage.error({
          grouping: true,
          message: res.msg,
        });
        return false;
      }
    } else {
      ElMessage.error({
        grouping: true,
        message: "Incorrect account or password format",
        center: false,
      });
      return false;
    }
  });
};
//退出登录
const loginOut = () => {
  console.log("sdsd");
  ElMessageBox.confirm("Are you sure you want out?", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    type: "warning",
  })
    .then(() => {
      ElMessage({
        type: "success",
        message: "Exit successfully",
      });
      removeToken();
      removeType();
      localStorage.clear();
      router.push({ path: "/onlineExpert" });
      // location.reload()
      if (router.currentRoute.value.path === "/onlineExpert") {
        fullscreenLoading.value = true;
        setTimeout(() => {
          fullscreenLoading.value = false;
          // location.reload()
          router.go(0);
        }, 10);
      }
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "unexit",
      });
    });
  return;

  // else{
  //   fullscreenLoading.value = true
  //   setTimeout(() => {
  //     router.push({ path: '/onlineExpert' })
  //     // location.reload()
  //     fullscreenLoading.value = false
  //   }, 500)
  // }
  // page1StoreDa.username = ''
};

// 请求用户信息
const requestUserInfo = async () => {
  const { code, data } = await request.get("/system/FaUser/info");
  if (code == 200) {
    userInfoData.value = data;
    mobleUserInfo.value.email = data.email;
    mobleUserInfo.value.nickname = data.nickname;
    // 储存用户信息
    store.commit("setUserInfo", JSON.stringify(data));
    da.name = JSON.parse(localStorage.getItem("userInfo"));
    //保存余额
    userStore.balance = data.coinBalance;
    userId.value = data.id;
    bus.emit("getUserId", data.id);
  }
};
//谷歌登录
function decodeJwtResponse(token) {
  console.log("?");
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

//google登录
async function handleCredentialResponse(response) {
  let responsePayload;
  if (response.access_token) {
    const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
      headers: { Authorization: `Bearer ${response.access_token}` },
    });
    if (res?.data) responsePayload = res.data;
  } else if (response.credential) {
    responsePayload = decodeJwtResponse(response.credential);
  }

  if (!responsePayload) {
    ElMessage.error({
      grouping: true,
      message: "Login failed",
    });
    return;
  }

  externalLogin.appId = responsePayload.sub;
  externalLogin.nickname = responsePayload.name;
  externalLogin.email = responsePayload.email;
  externalLogin.avatar = responsePayload.picture;
  externalLogin.type = "1";
  exportLogin();
  da.isRegisterShow = false;
}

const exportLogin = async () => {
  if (externalLogin.type == "2") {
    console.log(externalLogin, "--externalLogin---");
  }
  let res = await request.post("/auth/faUser/ThirdPartyLogin", externalLogin);
  if (res.code == 200) {
    setToken(res.data.access_token);
    da.token = getToken();
    window.localStorage.setItem("token", res.data.access_token);
    da.isLoginShow = false;
    page1StoreDa.username = loginForm.value.loginEmail;
    ElMessage.success({
      grouping: true,
      message: "Login succeeded",
    });
    loginForm.value.loginEmail = "";
    loginForm.value.password = "";
    requestUserInfo();
  } else {
    ElMessage({
      type: "error",
      grouping: true,
      message: res.msg,
    });
  }
};
//facebook登录
const facebookLogin = () => {
  FB.init({
    appId: "1304073757052736", //facebook申请的的appid
    //  appId: '985668249121343',
    cookie: true, // enable cookies to allow the server to access
    xfbml: true, // parse social plugins on this page
    version: "v2.8", // use graph api version 2.8
    // redirect_uri: '192.168.1.110:9200/callback/getCode'
  });
  FB.login(
    (response) => {
      if (response.status === "connected") {
        console.log(response, "facebook数据");
        externalLogin.appId = response.authResponse.userID;
        FB.api("/me?fields=id,name, email", (response) => {
          externalLogin.nickname = response.name;
          externalLogin.email = response.email;
          externalLogin.type = "2";
          exportLogin();
          da.isRegisterShow = false;
        });
      } else {
        console.log(response);
      }
    }, //Facebook登录成功之后的回调函数，可以在这里获取数据和将sccessToken返回后端
    { scope: "email,public_profile" } //声明Facebook登录所需要返回的数据
  );
};
//会员注册
const registerFromRef = ref(null);
const registerFromRefCode = ref(null);
const registerFn = () => {
  registerFromRef.value.validate(async (valid) => {
    if (valid) {
      console.log(valid, "res");

      // regLoading.value = true
      let { email, password } = RegisterForm.value;
      const mailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!email) {
        error.register.email = "Email cannot be empty";
        return;
      } else if (!mailReg.test(email)) {
        console.log(mailReg.test(email), "result");
        error.register.email = "Please enter a valid email address";
        return;
      } else {
        error.register.email = false;
      }

      const pwdReg = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/;
      if (!password) {
        error.register.password = "Please enter the password";
        return;
      } else if (!pwdReg.test(password)) {
        error.register.password = "Please enter your password no less than 6 characters.";
        return;
      } else {
        error.register.password = false;
      }

      try {
        let data = {
          email: email,
        };
        createLoading.value = true;
        let res = await request.post("/auth/faUser/sendVerifyCode", data);
        console.log(res, "value of res");
        if (res.code == 200) {
          ElMessage({ type: "success", message: "success" });
          createLoading.value = false;
          da.isRegisterShowCode = true;
          da.isRegisterShow = false;
        } else if (res.code == 500) {
          ElMessage({
            type: "error",
            grouping: true,
            message: res.msg,
          });
          // waitTime.value--
          // getCodeBtnDisable.value = true
          createLoading.value = false;
          // codeBtnWord.value = `${waitTime.value}s send`
          // let timer = setInterval(function () {
          //   if (waitTime.value > 1) {
          //     waitTime.value--
          //     codeBtnWord.value = `${waitTime.value}s send`
          //   } else {
          //     clearInterval(timer)
          //     codeBtnWord.value = 'Join Now for FREE'
          //     getCodeBtnDisable.value = false
          //     waitTime.value = 60
          //   }
          // }, 1000)
        }
      } catch (res) {
        console.log(res);
      }
    } else {
      // page1StoreDa.isRegisterShow = false
      // page1StoreDa.isLoginShow = true
    }
  });
};
const nextYz = async () => {
  let { email } = RegisterForm.value;
  if (!email) {
    return;
  }
  try {
    let data = {
      email: email,
    };
    createLoading.value = true;
    let res = await request.post("/auth/faUser/sendVerifyCode", data);
    console.log(res, "value of res");
    if (res.code == 200) {
      ElMessage({ type: "success", message: "success" });
      createLoading.value = false;
      da.isRegisterShowCode = true;
      da.isRegisterShow = false;
    } else if (res.code == 500) {
      ElMessage({
        type: "error",
        grouping: true,
        message: res.msg,
      });
      // waitTime.value--
      // getCodeBtnDisable.value = true
      createLoading.value = false;
      // codeBtnWord.value = `${waitTime.value}s send`
      // let timer = setInterval(function () {
      //   if (waitTime.value > 1) {
      //     waitTime.value--
      //     codeBtnWord.value = `${waitTime.value}s send`
      //   } else {
      //     clearInterval(timer)
      //     codeBtnWord.value = 'Join Now for FREE'
      //     getCodeBtnDisable.value = false
      //     waitTime.value = 60
      //   }
      // }, 1000)
    }
  } catch (res) {
    console.log(res);
  }
};
const FinishFning = ref(false);

const debounceRegisterFn = debounce(registerFn, 500); // 500表示延迟时间，您可以根据需要进行调整
const debounceNextYz = debounce(nextYz, 500);

const FinishFn = () => {
  registerFromRefCode.value.validate(async (val) => {
    if (val) {
      console.log("okok");
      FinishFning.value = true;
      let datas = {
        email: RegisterForm.value.email,
        password: RegisterForm.value.password,
        code: RegisterFormCode.value.code,
      };
      if (!datas.code) {
        error.register.code = "The verification code cannot be empty";
        return;
      } else {
        error.register.code = false;
      }
      const res = await register(datas);
      if (res.code == 200) {
        FinishFning.value = false;
        ElMessage({ type: "success", message: res.data });
        da.isRegisterShowCode = false;
        da.isLoginShow = true;
      } else if (res.code == 500) {
        FinishFning.value = false;
        ElMessage({
          type: "error",
          grouping: true,
          message: res.msg,
        });
      }
    }
  });
};

const debounceFinishFn = debounce(FinishFn, 700);

// // 检查浏览器是否支持弹出窗口
// const isWindowOpenAllowed = () => {
//   return window.open && true;
// };

// // 检查是否有权限打开弹出窗口
// const isPopupBlocked = () => {
//   const newWindow = window.open("", "_blank");
//   if (newWindow) {
//     newWindow.close();
//     return false;
//   } else {
//     return true;
//   }
// };

//点数购买
const paypalFn = async (val, index, id) => {
  if (window.innerWidth < 768) {
    ElMessage({
      message: "Make sure you are allowed to open a new window",
      type: "warning",
    });
  }

  checkCredits(val, index, id);
  if (!payid.value) {
    ElMessage({
      message: "You have not selected the amount of points you need to purchase yet!",
      type: "warning",
    });
    return;
  }
  const loading = ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  const { code, data } = await request.post("/system/paypal/pay", {
    id: payid.value,
    returnUrl: window.location.origin + "/#/returnHref",
  });
  if (code == 200) {
    if (!data) {
      ElMessage({
        message: "The payment window failed to open!",
        type: "warning",
      });
      loading.close();
      return;
    }
    loading.close();
    window.open(
      data,
      "paypal",
      "top=255,left=280,toolbar=no,status=no,location=no,resizable=no,menubar=no,scrollbars=no,resizable=no,width=850,height=850"
    );
  } else {
    loading.close();
    ElMessage({
      message: "error!",
      type: "warning",
    });
  }
};
// 关闭点数购买弹出
async function closPay() {
  da.isPayShow = false;
  await request.get("/system/paypal/payCancel");
  emit("closPay", false);
}

const checkCredits = (val, index, id) => {
  console.log(val, "选择");
  da.selected = index;
  da.checkList = val;
  payid.value = id;
};

const goReg = () => {
  da.isLoginShow = false;
  da.isRegisterShow = true;
  page1StoreDa.isLoginShow = false;
  page1StoreDa.isRegisterShow = true;
};

const goLogin = () => {
  da.isLoginShow = true;
  da.isRegisterShow = false;
  page1StoreDa.isRegisterShow = false;
  page1StoreDa.isLoginShow = true;
};

const goLoveList = () => {
  if (route.path == "/callPlayer/receive") {
    ElMessageBox.alert("Hang up the phone before you leave this page!", "Warm and cozy", {
      confirmButtonText: "OK",
    });
    return;
  }
  router.push("/favorite");
};
const goHistory = () => {
  if (route.path == "/callPlayer/receive") {
    ElMessageBox.alert("Hang up the phone before you leave this page!", "Warm and cozy", {
      confirmButtonText: "OK",
    });
    return;
  }
  router.push("/history");
};
const goSetting = () => {
  if (route.path == "/callPlayer/receive") {
    ElMessageBox.alert("Hang up the phone before you leave this page!", "Warm and cozy", {
      confirmButtonText: "OK",
    });
    return;
  }
  if (window.innerWidth > 768 && route.path !== "/callPlayer/receive") {
    router.push("/pageSetting");
  } else {
    movieSet.value = true;
    isCenter.value = false;
  }
};
</script>
