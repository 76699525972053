import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

const ExpiresInKey = 'Admin-Expires-In'

const ConType = 'Consulant-Type'

const unverType = 'unver-Type'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getExpiresIn() {
  return Cookies.get(ExpiresInKey) || -1
}

export function setExpiresIn(time) {
  return Cookies.set(ExpiresInKey, time)
}

export function removeExpiresIn() {
  return Cookies.remove(ExpiresInKey)
}

export function getType() {
  return Cookies.get(ConType)
}

export function setType(type) {
  return Cookies.set(ConType, type)
}

export function removeType() {
  return Cookies.remove(ConType)
}

export function getConlant() {
  return Cookies.get(unverType)
}

export function setConlant(type) {
  return Cookies.set(unverType, type)
}

export function removeConlant() {
  return Cookies.remove(unverType)
}
